import React from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
    ChangePassword,
    CreateClinic,
    Connections,
    CreateLab,
    CreateUser,
    CasesDashboard,
    Login,
    NotFound,
    NotProtectedRoute,
    ProtectedRoute,
    Clinics,
    Labs,
    Users,
    RegisterLab,
    RegisterClinic,
    // Profile,
    // Register,
    // PasswordRecovery,
    PasswordReset,
    // Connections,
} from './routes';
import { getRole } from './helpers';
import { updateRoleAction } from './redux/reducers/global.reducer';

function App() {
    const dispatch = useDispatch();
    dispatch(updateRoleAction(getRole()));
    return (
        <Router>
            <Routes>
                <Route element={<NotProtectedRoute />}>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register-lab" element={<RegisterLab />} />
                    <Route path="register-clinic" element={<RegisterClinic />} />
                    <Route path="/password-reset" element={<PasswordReset />} />
                </Route>
                {/* <Route path="/password-recovery" element={<PasswordRecovery />} /> */
                /* <Route path="/register" element={<Register />} /> */}
                <Route element={<ProtectedRoute />}>
                    <Route path="*" element={<NotFound />} />
                    <Route path="/cases" element={<CasesDashboard />} />
                    {/* <Route path="/dashboard/profile" element={<Profile />} />
                    <Route path="/dashboard/connections" element={<Connections />} /> */}
                </Route>
                <Route element={<ProtectedRoute rule="users:write" />}>
                    <Route path="/create-user" element={<CreateUser />} />
                </Route>
                <Route element={<ProtectedRoute rule="clinics:write" />}>
                    <Route path="/create-clinic" element={<CreateClinic />} />
                </Route>
                <Route element={<ProtectedRoute rule="labs:write" />}>
                    <Route path="/create-lab" element={<CreateLab />} />
                </Route>
                <Route element={<ProtectedRoute rule="connections:read" />}>
                    <Route path="/connections" element={<Connections />} />
                </Route>
                <Route element={<ProtectedRoute rule="profile" />}>
                    <Route path="/change-password" element={<ChangePassword />} />
                </Route>
                <Route element={<ProtectedRoute rule="clinics:write" />}>
                    <Route path="/clinics" element={<Clinics />} />
                </Route>
                <Route element={<ProtectedRoute rule="labs:write" />}>
                    <Route path="/labs" element={<Labs />} />
                </Route>
                <Route element={<ProtectedRoute rule="users:write" />}>
                    <Route path="/users" element={<Users />} />
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
