import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function TabContent({ children }) {
    return (
        <div className="tab-content">
            {
                children
            }
        </div>
    );
}

TabContent.propTypes = {
    children: PropTypes.node,
};

TabContent.defaultProps = {
    children: null,
};

export default TabContent;
