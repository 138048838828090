/* eslint-disable */
import './styles.scss';
import { Alert, Button, Grid, Divider } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Header, Input } from '../../components';
import { http } from '../../helpers';

function RegisterLab() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [invitationId, setInvitationId] = useState(null);
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [labEmail, setLabEmail] = useState('');
    const [officePhone, setOfficePhone] = useState('');
    const [cellPhone, setCellPhone] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [username, setUsername] = useState('');
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Something went wrong');
    const [error, setError] = useState({
        name: false,
        username: false,
        labEmail: false,
        userEmail: false,
        password: false,
        confirmPassword: false,
    });

    useEffect(() => {
        const tokenParam = searchParams.get('token');
        const invitationIdParam = searchParams.get('invitationId');

        if (!tokenParam || !invitationIdParam) navigate('/login');

        setToken(tokenParam);
        setInvitationId(invitationIdParam);
    }, []);

    const validate = () => {
        setError({
            name: !name,
            labEmail: !labEmail,
            username: !username,
            userEmail: !userEmail,
            password: password.length < 6,
            confirmPassword: password !== confirmPassword,
        });
        return (
            !!name
            && !!labEmail
            && !!username
            && !!userEmail
            && password.length >= 6
            && password === confirmPassword
        );
    };

    const onSubmit = () => {
        const isValid = validate();
        if (!isValid) return;
        const url = '/case-api/registrations';
        const lab = { name, company, officePhone, cellPhone, email: labEmail };
        const user = { firstName, lastName, password, username, email: userEmail };
        const body = { token, invitationId, lab, user };

        http
            .postAnonymous(url, body)
            .then(() => {
                setSuccess(true);
                setFail(false);
                setTimeout(() => {
                    navigate('/login');
                }, 700);
            })
            .catch(({ request }) => {
                const response = JSON.parse(request?.response);
                const err = response?.error;
                if (err) setErrorMessage(err);
                setSuccess(false);
                setFail(true);
            });
    };
    return (
        <>
            <Header label="Register Lab" showMenu={false} />
            <main className="register-lab-container">
                <Grid spacing={4} container direction="column" alignItems="center">
                    <Grid
                        className="form-field"
                        item
                        style={{ height: '50px', paddingTop: '10px' }}
                    >
                        {success && (
                            <Alert>
                                Success! Lab registered
                            </Alert>
                        )}
                        {fail && (
                            <Alert severity="error">
                                { errorMessage }
                            </Alert>
                        )}
                    </Grid>
                    <Grid item className="divider">
                        <Divider textAlign="center">Lab Info</Divider>
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Lab Name"
                            variant="outlined"
                            value={name}
                            required
                            error={error.name}
                            helperText={error.name ? 'Lab name field is required' : ''}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Lab Email"
                            variant="outlined"
                            value={labEmail}
                            required
                            error={error.labEmail}
                            helperText={error.labEmail ? 'Lab Email field is required' : ''}
                            onChange={(e) => setLabEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Company Name"
                            variant="outlined"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Office Phone"
                            variant="outlined"
                            value={officePhone}
                            onChange={(e) => setOfficePhone(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Cell Phone"
                            variant="outlined"
                            value={cellPhone}
                            onChange={(e) => setCellPhone(e.target.value)}
                        />
                    </Grid>
                    <Grid item className="divider">
                        <Divider textAlign="center">User Info</Divider>
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Username"
                            variant="outlined"
                            value={username}
                            required
                            error={error.username}
                            helperText={error.username ? 'Username field is required' : ''}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="User Email"
                            variant="outlined"
                            value={userEmail}
                            required
                            error={error.userEmail}
                            helperText={error.userEmail ? 'User Email field is required' : ''}
                            onChange={(e) => setUserEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="First Name"
                            variant="outlined"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Last Name"
                            variant="outlined"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Password"
                            required
                            type="password"
                            variant="outlined"
                            value={password}
                            error={error.password}
                            helperText={error.password ? 'Password must be at least 6 characters' : ''}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Confirm Password"
                            required
                            type="password"
                            variant="outlined"
                            value={confirmPassword}
                            error={error.confirmPassword}
                            helperText={error.confirmPassword ? 'Passwords do not match' : ''}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={onSubmit}
                            variant="outlined"
                            style={{ border: '1px solid black', color: 'black' }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </main>
        </>
    );
}

export default RegisterLab;
