import PropTypes from 'prop-types';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '..';

function ConfirmationModal({ show, title, onClose, message, onConfirm }) {
    return (
        <Modal show={show}>
            <ModalHeader closeHandler={onClose}>
                <span>{title}</span>
            </ModalHeader>
            <ModalBody>
                <span>{message}</span>
            </ModalBody>
            <ModalFooter>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <button className="btn" type="button" onClick={onConfirm}>Confirm</button>
                    <button className="btn" type="button" onClick={onClose}>Cancel</button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

ConfirmationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    message: PropTypes.string,
    title: PropTypes.string,
};

ConfirmationModal.defaultProps = {
    message: 'Are you sure you want to delete this item',
    title: 'Confirm',
};

export default ConfirmationModal;
