import PropTypes from 'prop-types';
import { List, Typography } from '@mui/material';
import NavItem from '../NavItem';

function NavGroup({ item }) {
    const items = item.children?.map((menu) => (
        <NavItem key={menu.id} item={menu} />
    ));

    return (
        <List
            subheader={
                item.title && (
                    <Typography
                        variant="caption"
                        sx={{
                            fontSize: '1.1rem',
                            fontWeight: 450,
                            color: 'black',
                            padding: '8px',
                            marginTop: '10px',
                        }}
                        display="block"
                        gutterBottom
                    >
                        {item.title}
                    </Typography>
                )
            }
        >
            {items}
        </List>
    );
}

NavGroup.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            icon: PropTypes.shape,
        })).isRequired,
    }).isRequired,
};

export default NavGroup;
