/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { deleteClinic, updateClinic } from '../../../redux/thunks';
import { ConfirmatinModal } from '../..';
import './styles.scss';

function ClinicDetail({ clinic }) {
    const dispatch = useDispatch();
    const [showConfirm, setShowConfirm] = useState(false);
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState(null);
    const [desc, setDesc] = useState(null);
    const [email, setEmail] = useState(null);
    const [cell, setCell] = useState(null);
    const [office, setOffice] = useState(null);
    const [address1, setAddress1] = useState(null);
    const [address2, setAddress2] = useState(null);
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [zip, setZip] = useState(null);

    const onDeleteClinic = () => {
        setShowConfirm(true);
    };

    const onConfirmDelete = () => dispatch(deleteClinic(clinic.id));
    const onCloseModal = () => setShowConfirm(false);
    const onSaveData = (evt) => {
        // TODO: see if blank values are allowable
        const clinicToSave = { ...clinic };
        if (name !== null) clinicToSave.name = name;
        if (desc !== null) clinicToSave.description = desc;
        if (address1 !== null) clinicToSave.address1 = address1;
        if (address2 !== null) clinicToSave.address2 = address2;
        if (city !== null) clinicToSave.city = city;
        if (state !== null) clinicToSave.state = state;
        if (zip !== null) clinicToSave.zip = zip;
        if (email !== null) clinicToSave.email = email;
        if (office !== null) clinicToSave.officePhone = office;
        if (cell !== null) clinicToSave.cellPhone = cell;
        dispatch(updateClinic(clinicToSave));

        setEditing('');
    };
    const onClickEdit = () => {
        setEditing(true);
    };
    const onCancelEdit = () => setEditing(false);

    const updateName = (evt) => setName(evt.target.value);
    const updateDesc = (evt) => setDesc(evt.target.value);
    const updateAddress1 = (evt) => setAddress1(evt.target.value);
    const updateAddress2 = (evt) => setAddress2(evt.target.value);
    const updateCity = (evt) => setCity(evt.target.value);
    const updateState = (evt) => setState(evt.target.value);
    const updateZip = (evt) => setZip(evt.target.value);
    const updateEmail = (evt) => setEmail(evt.target.value);
    const updateOffice = (evt) => setOffice(evt.target.value);
    const updateCell = (evt) => setCell(evt.target.value);
    return (
        <div style={{ position: 'relative' }}>
            <div className="details">
                { editing
                    ? (
                        <div className="clinic-form-container">
                            <TextField
                                className="form-field"
                                style={{ gridArea: 'name' }}
                                label="Name"
                                variant="outlined"
                                defaultValue={clinic.name}
                                onChange={updateName}
                            />
                            <TextField
                                className="form-field"
                                style={{ gridArea: 'email' }}
                                label="Email"
                                variant="outlined"
                                defaultValue={clinic.email}
                                onChange={updateEmail}
                            />
                            <TextField
                                className="form-field"
                                style={{ gridArea: 'cellphone' }}
                                label="Cell Phone"
                                variant="outlined"
                                defaultValue={clinic.cellPhone}
                                onChange={updateCell}
                            />
                            <TextField
                                className="form-field"
                                style={{ gridArea: 'description' }}
                                label="Description"
                                variant="outlined"
                                defaultValue={clinic.description}
                                onChange={updateDesc}
                            />
                            <TextField
                                className="form-field"
                                style={{ gridArea: 'office' }}
                                label="Office Phone"
                                variant="outlined"
                                defaultValue={clinic.officePhone}
                                onChange={updateOffice}
                            />
                            <TextField
                                className="form-field"
                                label="Street Address"
                                variant="outlined"
                                defaultValue={clinic.address1}
                                onChange={updateAddress1}
                            />
                            <TextField
                                className="form-field"
                                label="Apt/Suite/PO Box"
                                variant="outlined"
                                defaultValue={clinic.address2}
                                onChange={updateAddress2}
                            />
                            <TextField
                                className="form-field"
                                label="City"
                                variant="outlined"
                                defaultValue={clinic.city}
                                onChange={updateCity}
                            />
                            <TextField
                                className="form-field"
                                label="State"
                                variant="outlined"
                                defaultValue={clinic.state}
                                onChange={updateState}
                            />
                            <TextField
                                className="form-field"
                                label="Zip"
                                variant="outlined"
                                defaultValue={clinic.zip}
                                onChange={updateZip}
                            />
                        </div>
                    ) : (
                        <div className="details-view">
                            <div>
                                <span>Name</span>
                                <p>{clinic.name}</p>
                                <span>Description</span>
                                <p>{clinic.description}</p>
                                <span>Address</span>
                                <p>{`${clinic.address1} ${clinic.address2 || ''} ${clinic.city}, ${clinic.state} ${clinic.zip || ''}`}</p>
                            </div>
                            <div>
                                <span>Email</span>
                                <p>{clinic.email}</p>
                            </div>
                            <div>
                                <span>Cell Phone</span>
                                <p>{clinic.cellPhone}</p>
                                <span>Office Phone</span>
                                <p>{clinic.officePhone}</p>
                            </div>
                        </div>
                    )}
            </div>
            <div className="edit-delete-container">
                { editing ? (
                    <button className="edit-btn" onClick={onSaveData} type="button">
                        <i className="material-icons clickable">save</i>
                    </button>
                )
                    : (
                        <button className="edit-btn" onClick={onClickEdit} type="button">
                            <i className="material-icons clickable">create</i>
                        </button>
                    ) }
                { editing ? (
                    <button
                        className="delete-btn"
                        onClick={onCancelEdit}
                        type="button"
                    >
                        <i className="material-icons clickable">cancel</i>
                    </button>
                )
                    : (
                        <button
                            className="delete-btn"
                            onClick={onDeleteClinic}
                            type="button"
                        >
                            <i className="material-icons clickable">delete</i>
                        </button>
                    ) }
            </div>
            <ConfirmatinModal
                show={showConfirm}
                message={`Are you sure you want to delete this ${clinic.name}?`}
                onConfirm={onConfirmDelete}
                onClose={onCloseModal}
            />
        </div>
    );
}

ClinicDetail.propTypes = {
    clinic: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        description: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        email: PropTypes.string,
        officePhone: PropTypes.string,
        cellPhone: PropTypes.string,
    }),
};

ClinicDetail.defaultProps = {
    clinic: {},
};
export default ClinicDetail;
