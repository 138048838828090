/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-case-declarations */

const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};
