import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function Tab({ label, active, onTabSelectHandler }) {
    return (
        <li className={`tab ${active ? 'active' : ''}`} onClick={() => onTabSelectHandler()} role="presentation">
            <span>{ label }</span>
        </li>
    );
}

Tab.propTypes = {
    label: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    onTabSelectHandler: PropTypes.func.isRequired,
};

export default Tab;
