import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function Modal({ show, children, className }) {
    return (
        <div className={`modal ${show ? 'show' : ''}`}>
            <div className={`modal-container ${className}`}>
                {
                    children
                }
            </div>
        </div>
    );
}

Modal.propTypes = {
    children: PropTypes.node,
    show: PropTypes.bool.isRequired,
    className: PropTypes.string,
};

Modal.defaultProps = {
    children: null,
    className: null,
};

export default Modal;
