/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { deleteLab, updateLab } from '../../../redux/thunks';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '../..';
import './styles.scss';

function LabDetail({ lab }) {
    const dispatch = useDispatch();
    const [showConfirm, setShowConfirm] = useState(false);
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [cell, setCell] = useState('');
    const [office, setOffice] = useState('');

    const onDeleteLab = () => {
        setShowConfirm(true);
    };

    const onConfirmDelete = () => dispatch(deleteLab(lab.id));
    const onCloseModal = () => setShowConfirm(false);
    const onSaveData = (evt) => {
        // TODO: see if blank values are allowable
        const labToSave = { ...lab };
        if (name) labToSave.name = name;
        if (company) labToSave.company = company;
        if (email) labToSave.email = email;
        if (office) labToSave.officePhone = office;
        if (cell) labToSave.cellPhone = cell;

        dispatch(updateLab(labToSave));

        setEditing('');
    };
    const onClickEdit = () => {
        setEditing(true);
    };
    const onCancelEdit = () => setEditing(false);

    const updateName = (evt) => setName(evt.target.value);
    const updateCompany = (evt) => setCompany(evt.target.value);
    const updateEmail = (evt) => setEmail(evt.target.value);
    const updateOffice = (evt) => setOffice(evt.target.value);
    const updateCell = (evt) => setCell(evt.target.value);
    return (
        <div style={{ position: 'relative' }}>
            <div className="details">
                { editing
                    ? (
                        <div className="lab-form-container">
                            <TextField
                                className="form-field"
                                style={{ gridArea: 'name' }}
                                label="Name"
                                variant="outlined"
                                defaultValue={lab.name}
                                onChange={updateName}
                            />
                            <TextField
                                className="form-field"
                                style={{ gridArea: 'email' }}
                                label="Email"
                                variant="outlined"
                                defaultValue={lab.email}
                                onChange={updateEmail}
                            />
                            <TextField
                                className="form-field"
                                style={{ gridArea: 'cellphone' }}
                                label="Cell Phone"
                                variant="outlined"
                                defaultValue={lab.cellPhone}
                                onChange={updateCell}
                            />
                            <TextField
                                className="form-field"
                                style={{ gridArea: 'company' }}
                                label="Company"
                                variant="outlined"
                                defaultValue={lab.company}
                                onChange={updateCompany}
                            />
                            <TextField
                                className="form-field"
                                style={{ gridArea: 'office' }}
                                label="Office Phone"
                                variant="outlined"
                                defaultValue={lab.officePhone}
                                onChange={updateOffice}
                            />
                        </div>
                    ) : (
                        <div className="details-view">
                            <div>
                                <span>Name</span>
                                <p>{lab.name}</p>
                                <span>Company</span>
                                <p>{lab.company}</p>
                            </div>
                            <div>
                                <span>Email</span>
                                <p>{lab.email}</p>
                            </div>
                            <div>
                                <span>Cell Phone</span>
                                <p>{lab.cellPhone}</p>
                                <span>Office Phone</span>
                                <p>{lab.officePhone}</p>
                            </div>
                        </div>
                    )}
            </div>
            <div className="edit-delete-container">
                { editing ? (
                    <button className="edit-btn" onClick={onSaveData} type="button">
                        <i className="material-icons clickable">save</i>
                    </button>
                )
                    : (
                        <button className="edit-btn" onClick={onClickEdit} type="button">
                            <i className="material-icons clickable">create</i>
                        </button>
                    ) }
                { editing ? (
                    <button
                        className="delete-btn"
                        onClick={onCancelEdit}
                        type="button"
                    >
                        <i className="material-icons clickable">cancel</i>
                    </button>
                )
                    : (
                        <button
                            className="delete-btn"
                            onClick={onDeleteLab}
                            type="button"
                        >
                            <i className="material-icons clickable">delete</i>
                        </button>
                    ) }
            </div>
            <Modal show={showConfirm}>
                <ModalHeader closeHandler={onCloseModal}>
                    <span>Confirm</span>
                </ModalHeader>
                <ModalBody>
                    <span>{`Are you sure you want to delete "${lab.name}"?`}</span>
                </ModalBody>
                <ModalFooter>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <button className="btn" type="button" onClick={onConfirmDelete}>Confirm</button>
                        <button className="btn" type="button" onClick={onCloseModal}>Cancel</button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}

LabDetail.propTypes = {
    lab: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        company: PropTypes.string,
        email: PropTypes.string,
        officePhone: PropTypes.string,
        cellPhone: PropTypes.string,
    }),
};

LabDetail.defaultProps = {
    lab: {},
};
export default LabDetail;
