import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// check is a token exists and is not expired.
const hasValidToken = () => {
    const { token, exp } = { ...localStorage };
    if (token && exp) {
        if (exp < new Date().getTime() / 1000) {
            localStorage.clear();
            return false;
        }
        return true;
    }
    return false;
};

function NotProtectedRoute() {
    return hasValidToken() ? <Navigate to="/cases" /> : <Outlet />;
}

export default NotProtectedRoute;
