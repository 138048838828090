import { http } from '../../helpers';
import { fetchConnections } from './fetchConnections';

export function deleteConnection(id) {
    return (dispatch) => {
        const relativeUrl = `/case-api/connections/${id}`;
        http.delete(relativeUrl)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    dispatch(fetchConnections());
                }
            })
            .catch((error) => {
                console.warn(error);
            });
    };
}
