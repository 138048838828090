import { http } from '../../helpers';

export function fetchClinics(pageNum, search) {
    return (dispatch) => {
        const relativeUrl = `/case-api/clinics?size=10&page=${pageNum || 0}&sort=name,id&search=${search || ''}&archived=false`;
        http.get(relativeUrl)
            .then(({ data }) => {
                dispatch({
                    type: 'clinics/UPDATE_STATE',
                    clinics: data.content,
                    pagination: {
                        number: data.number,
                        totalPages: data.totalPages,
                        first: data.first,
                        last: data.last,
                    },
                });
            })
            .catch((error) => {
                console.warn(error);
            });
    };
}
