const config = {
    domain: process.env.REACT_APP_DOMAIN,
    apiUrl: process.env.REACT_APP_API_URL,
    openIdUrl: process.env.REACT_APP_OPENID_CONFIG_URL,
};

if (!config.domain) throw new Error('MISSING REACT_APP_DOMAIN');
if (!config.apiUrl) throw new Error('MISSING REACT_APP_API_URL');
if (!config.openIdUrl) throw new Error('MISSING REACT_APP_OPENID_CONFIG_URL');

export default config;
