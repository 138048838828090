const initialState = {
    users: null,
    pagination: {
        number: 0,
        totalPages: 0,
        first: null,
        last: null,
    },
    filters: {
        clinicId: '',
        labId: '',
        enabled: true,
    },
};
export default (state = initialState, action) => {
    const { type: actionType, ...changes } = action;
    switch (action.type) {
        case 'users/UPDATE_STATE':
            return { ...state, ...changes };
        case 'users/UPDATE_FILTER':
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...changes,
                },
            };
        default:
            return state;
    }
};
