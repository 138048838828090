import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'query-string';
import './styles.scss';
import { Spinner } from '../../components';
import logo from '../../assets/images/ori_logo.png';
import config from '../../config';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            officePhone: '',
            cellPhone: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            password: '',
            confirm: '',
            error: '',
            emailIsAvailable: false,
            submitSuccess: false,
            submitting: false,
        };

        if (!this.tokenExists()) {
            props.history.push('/');
        }
    }

    tokenExists = () => {
        const params = window.location.hash.split('?')[1];
        const { token } = qs.parse(params);
        return token;
    };

    emailCheck = () => {
        const { email } = this.state;
        axios.get(`${config.apiUrl}/auth-api/clients/availability?clientId=${email}`)
            .then(({ data }) => this.onEmailCheckSuccess(data))
            .catch((error) => console.warn(error));
    };

    onEmailCheckSuccess = (isAvailable) => {
        if (!isAvailable) {
            this.setState({ emailIsAvailable: false, error: 'Email already exists' });
        } else {
            this.setState({ emailIsAvailable: true, error: '' });
        }
    };

    changeHandler = ({ name, value }) => {
        this.setState({ [name]: value });
    };

    submitHandler = (event) => {
        event.preventDefault();
        const {
            name,
            email,
            officePhone,
            cellPhone,
            address1,
            address2,
            city,
            state,
            zip,
            password,
            confirm,
        } = this.state;
        this.setState({ error: '', submitting: true });
        if (password !== confirm) {
            this.setState({ error: 'Password do not match' });
        }
        axios.post('/auth-api/users', { username: email, password })
            .then((response) => axios.post('/case-api/labs', { name, email, officePhone, cellPhone, address1, address2, city, state, zip, userUuid: response }))
            .then((response) => this.onSubmitSuccess(response))
            .catch((error) => console.warn(error));
    };

    onSubmitSuccess = (response) => {
        console.log(response);
        this.setState({ submitSuccess: true });
    };

    // onSubmitError = (error) => {
    //     this.setState({ submitting: false });
    //     console.warn(error);
    // };

    formIsValid = () => {
        const {
            name,
            email,
            officePhone,
            address1,
            city,
            state,
            zip,
            password,
            confirm,
        } = this.state;
        return name.length
               && email.length
               && officePhone.length
               && address1.length
               && city.length
               && state.length
               && zip.length
               && password.length
               && confirm.length;
    };

    render() {
        const { error, emailIsAvailable, submitSuccess, submitting } = this.state;
        const { history } = this.props;

        const form = (
            <div className="register-container">
                <img src={logo} alt="Ori Dental Scanner logo" />
                <h6>ORI DENTAL SCANNER</h6>
                <p className="text-center text-red text-bold">
                    {
                        error
                    }
                </p>
                <form onSubmit={this.submitHandler}>
                    <label htmlFor="name">
                        *Name
                        <input name="name" placeholder="Name" onChange={(e) => this.changeHandler(e.target)} />
                    </label>
                    <label htmlFor="email">
                        *Email
                        <input className={!emailIsAvailable ? 'error' : ''} type="email" name="email" placeholder="example@gmail.com" onChange={(e) => this.changeHandler(e.target)} onBlur={() => this.emailCheck()} />
                    </label>
                    <div className="input-group">
                        <div className="input-group-column span-6">
                            <label htmlFor="officephone">
                                *Office Phone
                                <input name="officePhone" placeholder="Office Phone" onChange={(e) => this.changeHandler(e.target)} />
                            </label>
                        </div>
                        <div className="input-group-column span-6">
                            <label htmlFor="personalphone">
                                Cell (optional)
                                <input name="personalPhone" placeholder="Personal Phone" onChange={(e) => this.changeHandler(e.target)} />
                            </label>
                        </div>
                    </div>
                    <label htmlFor="address">
                        *Address
                        <input name="address1" placeholder="Address" onChange={(e) => this.changeHandler(e.target)} />
                    </label>
                    <label htmlFor="address">
                        Address 2 (optional)
                        <input name="address2" placeholder="Address 2" onChange={(e) => this.changeHandler(e.target)} />
                    </label>
                    <div className="input-group">
                        <div className="input-group-column span-5">
                            <label htmlFor="city">
                                *City
                                <input name="city" placeholder="City" onChange={(e) => this.changeHandler(e.target)} />
                            </label>
                        </div>
                        <div className="input-group-column span-2">
                            <label htmlFor="state">
                                *State
                                <input name="state" placeholder="State" onChange={(e) => this.changeHandler(e.target)} />
                            </label>
                        </div>
                        <div className="input-group-column span-5">
                            <label htmlFor="zip">
                                *Zip
                                <input name="zip" placeholder="Zip" onChange={(e) => this.changeHandler(e.target)} />
                            </label>
                        </div>
                    </div>
                    <div className="input-group">
                        <div className="input-group-column">
                            <label htmlFor="password">
                                *Password
                                <input name="password" type="password" placeholder="Password" onChange={(e) => this.changeHandler(e.target)} />
                            </label>
                        </div>
                        <div className="input-group-column">
                            <label htmlFor="confirm">
                                *Confirm
                                <input name="confirm" type="password" placeholder="Confirm" onChange={(e) => this.changeHandler(e.target)} />
                            </label>
                        </div>
                    </div>
                    <button className="btn btn-dark" type="submit" disabled={!this.formIsValid() || submitting}>
                        Submit
                        {
                            submitting && <Spinner type="inline" />
                        }
                    </button>
                    <button className="btn" type="button" onClick={() => history.push('/login')} disabled={submitting}>Back to Login</button>
                </form>
            </div>
        );

        const success = (
            <div className="register-container success">
                <img src={logo} alt="Ori Dental Scanner logo" />
                <h6>ORI DENTAL SCANNER</h6>
                <i className="material-icons">check_circle_outline</i>
                <h3>Account Successfully Created</h3>
                <p>Please return to the login page where you can login with your email and password.</p>
                <button className="btn btn-dark" type="button" onClick={() => history.push('/login')}>Login</button>
            </div>
        );

        return (
            submitSuccess ? success : form
        );
    }
}

Register.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func.isRequired }),
};

Register.defaultProps = {
    history: null,
};

export default Register;
