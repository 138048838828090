import PropTypes from 'prop-types';
import Case from './Case';
import {
    Table,
    TableHead,
    TableBody,
    TableHeading,
    TableRow,
} from '..';

function Cases({ cases }) {
    const { scope } = localStorage;
    const scopeArray = (scope || '').split(' ');
    const hasAccess = (rule) => scopeArray.includes(rule);
    const canViewClinicsColumn = hasAccess('cases.display.clinics:read');
    const canViewLabsColumn = hasAccess('cases.display.labs:read');
    let row;

    if (canViewClinicsColumn && canViewLabsColumn) {
        row = (
            <TableRow>
                <TableHeading heading="Status" span="1" />
                <TableHeading heading="Patient Name" span="2" />
                <TableHeading heading="Doctor Name" span="2" />
                <TableHeading heading="Clinic" span="2" />
                <TableHeading heading="Lab" span="1" />
                <TableHeading heading="Order #" span="1" />
                <TableHeading heading="Created" span="1" />
                <TableHeading heading="Received" span="1" />
                <TableHeading heading="Due" span="1" />
            </TableRow>
        );
    } else if (canViewClinicsColumn && !canViewLabsColumn) {
        row = (
            <TableRow>
                <TableHeading heading="Status" span="1" />
                <TableHeading heading="Patient Name" span="3" />
                <TableHeading heading="Doctor Name" span="2" />
                <TableHeading heading="Clinic" span="2" />
                <TableHeading heading="Order #" span="1" />
                <TableHeading heading="Created" span="1" />
                <TableHeading heading="Received" span="1" />
                <TableHeading heading="Due" span="1" />
            </TableRow>
        );
    } else if (!canViewClinicsColumn && canViewLabsColumn) {
        row = (
            <TableRow>
                <TableHeading heading="Status" span="1" />
                <TableHeading heading="Patient Name" span="3" />
                <TableHeading heading="Doctor Name" span="2" />
                <TableHeading heading="Lab" span="2" />
                <TableHeading heading="Order #" span="1" />
                <TableHeading heading="Created" span="1" />
                <TableHeading heading="Received" span="1" />
                <TableHeading heading="Due" span="1" />
            </TableRow>
        );
    } else {
        row = (
            <TableRow>
                <TableHeading heading="Status" span="1" />
                <TableHeading heading="Patient Name" span="4" />
                <TableHeading heading="Doctor Name" span="3" />
                <TableHeading heading="Order #" span="1" />
                <TableHeading heading="Created" span="1" />
                <TableHeading heading="Received" span="1" />
                <TableHeading heading="Due" span="1" />
            </TableRow>
        );
    }

    return (
        <Table>
            <TableHead>
                { row }
            </TableHead>
            <TableBody>
                {
                    cases.map((c) => <Case {...c} key={c.id} />)
                }
            </TableBody>
        </Table>
    );
}

Cases.propTypes = {
    cases: PropTypes.arrayOf(PropTypes.shape),
};

Cases.defaultProps = {
    cases: [],
};

export default Cases;
