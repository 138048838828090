import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchUsers } from '../../redux/thunks';
import { filterUsersAction } from '../../redux/actions';
import {
    Header,
    UserList,
    Spinner,
    Pagination,
    Row,
    LabPicker,
    ClinicPicker,
    Toggle,
} from '../../components';
import './styles.scss';

export default function Users() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const users = useSelector((state) => state.users.users);
    const pagination = useSelector((state) => state.users.pagination);
    const filters = useSelector((state) => state.users.filters);
    const [fetching, setFetching] = useState(false);
    const [pageNum, setPageNum] = useState(0);
    const [clinic, setClinic] = useState('');
    const [lab, setLab] = useState('');
    const [enabled, setEnabled] = useState(true);

    useEffect(() => {
        if (fetching && users) {
            setFetching(false);
        }
    }, [users]);

    useEffect(() => {
        setFetching(true);
        dispatch(fetchUsers(pageNum));
    }, [pageNum, filters]);

    const onAddUser = () => {
        navigate('/create-user');
    };
    const onFilterLab = (labId) => {
        dispatch(filterUsersAction({ labId }));
        setLab(labId);
    };
    const onFilterClinic = (clinicId) => {
        dispatch(filterUsersAction({ clinicId }));
        setClinic(clinicId);
    };
    const onFilterEnabled = (evt) => {
        const val = evt.target.checked;
        dispatch(filterUsersAction({ enabled: val }));
        setEnabled(val);
    };
    return (
        <>
            <Header label="Users" />
            <main className="dashboard-container">
                <Row>
                    <div className="user-header">
                        <LabPicker onSelectLab={onFilterLab} selectedLab={lab} />
                        <ClinicPicker onSelectClinic={onFilterClinic} selectedClinic={clinic} />
                        <Toggle
                            label="Enabled"
                            checked={enabled}
                            onChange={onFilterEnabled}
                        />
                        <i
                            title="Add new user"
                            role="presentation"
                            className="material-icons fz-50 clickable"
                            onClick={onAddUser}
                        >
                            add_circle
                        </i>
                    </div>
                </Row>
                {
                    fetching && <Spinner type="page" />
                }
                {
                    users && !users.length && !fetching
                        ? <p className="text-red text-align-center">No users found</p>
                        : <UserList users={users} />
                }
                <Pagination onChangePage={setPageNum} pagination={pagination} />
            </main>
        </>

    );
}
