import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { fetchLabs } from '../../redux/thunks';
import {
    Header,
    LabList,
    Search,
    Spinner,
    Pagination,
    Row,
} from '../../components';
import './styles.scss';

export default function Labs() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const labs = useSelector((state) => state.labs.labs);
    const pagination = useSelector((state) => state.labs.pagination);
    const [fetching, setFetching] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (fetching && labs) {
            setFetching(false);
        }
    }, [labs]);

    useEffect(() => {
        setFetching(true);
        dispatch(fetchLabs({ page: 0, search }));
    }, []);

    const onChangePage = (page) => {
        setFetching(true);
        dispatch(fetchLabs({ page, search }));
    };

    const onUpdateSearch = debounce((searchStr) => {
        setSearch(searchStr);
        dispatch(fetchLabs({ page: 0, search: searchStr }));
    }, 500);
    const onAddLab = () => {
        navigate('/create-lab');
    };
    return (
        <>
            <Header label="Labs" />
            <main className="dashboard-container">
                <Row>
                    <div className="clinic-header">
                        <Search onChange={onUpdateSearch} label="Search lab name" />
                        <i title="Add new lab" role="presentation" className="material-icons fz-50 clickable" onClick={onAddLab}>add_circle</i>
                    </div>
                </Row>
                {
                    fetching && <Spinner type="page" />
                }
                {
                    labs && !labs.length && !fetching
                        ? <p className="text-red text-align-center">No labs found</p>
                        : <LabList labs={labs} />
                }
                <Pagination onChangePage={onChangePage} pagination={pagination} />
            </main>
        </>
    );
}
