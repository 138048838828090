import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Connection from './Connection';
import { http, CLINIC, LAB, ADMIN } from '../../helpers';
import { deleteConnection, fetchConnections } from '../../redux/thunks';
import { chooseLabAction, chooseClinicAction } from '../../redux/reducers';
import {
    Header,
    AddConnectionForm,
    Modal,
    Spinner,
    Pagination,
    ClinicPicker,
    LabPicker,
} from '../../components';
import './styles.scss';

const getTitleBasedOnRole = (role) => {
    if (role === LAB) return 'Clinic Connections';
    if (role === CLINIC) return 'Lab Connections';
    return 'Connections';
};

function Connections() {
    const dispatch = useDispatch();
    const role = useSelector((state) => state.global.role);
    const connections = useSelector((state) => state.connections.connections);
    const pagination = useSelector((state) => state.connections.pagination);
    const selectedClinic = useSelector((state) => state.connections.chosenClinic);
    const selectedLab = useSelector((state) => state.connections.chosenLab);
    const [showModal, setShowModal] = useState(false);
    const [entities, setEntities] = useState([]);
    const [title, setTitle] = useState('Connections');

    const getConnections = () => {
        dispatch(fetchConnections());
    };

    const pagingParams = 'page=0&size=1000&sort=name,id';

    const getPotentialConnections = () => {
        if (role === CLINIC) {
            http.get(`/case-api/connections/search/labs?${pagingParams}`)
                .then(({ data }) => setEntities(data.content))
                .catch(console.error);
        } else if (role === LAB) {
            http.get(`/case-api/connections/search/clinics?${pagingParams}`)
                .then(({ data }) => setEntities(data.content))
                .catch(console.error);
        } else if (role === ADMIN) {
            console.log(selectedClinic, selectedLab);
            if (selectedClinic) {
                http.get(`/case-api/connections/search/labs?${pagingParams}&clinicId=${selectedClinic}`)
                    .then(({ data }) => setEntities(data.content))
                    .catch(console.error);
            } else if (selectedLab) {
                http.get(`/case-api/connections/search/clinics?${pagingParams}&labId=${selectedLab}`)
                    .then(({ data }) => setEntities(data.content))
                    .catch(console.error);
            }
        }
    };

    const onConfirmDelete = (id) => {
        dispatch(deleteConnection(id));
    };

    const getProps = (connection) => {
        if (role === LAB) return connection.clinic;
        if (role === CLINIC) return connection.lab;
        if (role === ADMIN) {
            if (selectedLab) return connection.clinic;
            if (selectedClinic) return connection.lab;
        }
        return connection.lab;
    };

    const onSelectClinic = (clinicId) => dispatch(chooseClinicAction(clinicId));
    const onSelectLab = (labId) => dispatch(chooseLabAction(labId));

    useEffect(() => {
        getPotentialConnections();
    }, [connections, selectedClinic]);

    useEffect(() => {
        getPotentialConnections();
        setTitle(getTitleBasedOnRole(role));
    }, []);

    useEffect(() => {
        if (selectedClinic) {
            dispatch(chooseLabAction(''));
            getConnections();
        }
    }, [selectedClinic]);

    useEffect(() => {
        if (selectedLab) {
            dispatch(chooseClinicAction(''));
            getConnections();
        }
    }, [selectedLab]);

    useEffect(() => {
        if (role !== ADMIN) getConnections();
    }, []);

    const view = (
        <div className="connections">
            { ((selectedLab || selectedClinic)) && (
                <div className="add-connection">
                    <div className="add-connection-content" onClick={() => setShowModal(true)} role="presentation">
                        <div className="add-user-icon">
                            <i className="material-icons">person_add</i>
                        </div>
                        <h3>Add New Contact</h3>
                    </div>
                </div>
            )}
            {
                connections && connections.length
                    ? connections.map((c) => <Connection key={c.id} props={getProps(c)} onDeleteConnection={() => onConfirmDelete(c.id)} />)
                    : (
                        role === ADMIN && !selectedClinic && !selectedLab
                            ? <div className="text-red text-align-center">Please select a clinic or a lab</div>
                            : <div className="text-red text-align-center">No Connections</div>
                    )
            }
            <div className="connections-pagination">
                <Pagination onChangePage={(newPage) => dispatch(fetchConnections({ page: newPage }))} pagination={pagination} />
            </div>
        </div>
    );

    return (
        <div>
            <div className="connections-container">
                <Header label={title} />
                { role === ADMIN && (
                    <div className="connections-clinicpicker-container">
                        <ClinicPicker
                            selectedClinic={selectedClinic}
                            onSelectClinic={onSelectClinic}
                        />
                        <LabPicker
                            selectedLab={selectedLab}
                            onSelectLab={onSelectLab}
                        />
                    </div>
                ) }
                {
                    !connections && (selectedClinic || selectedLab) ? <Spinner type="page" /> : view
                }
                <Modal show={showModal}>
                    <i className="material-icons" role="presentation" onClick={() => setShowModal(false)}>close</i>
                    {/* rendering logic below will trigger AddConnectionForm cleanup function upon close */}
                    { showModal && (
                        <AddConnectionForm
                            chosenLab={selectedLab}
                            chosenClinic={selectedClinic}
                            closeModalHandler={() => setShowModal(false)}
                            entities={entities}
                            refreshLabs={() => {
                                setShowModal(false);
                                getConnections();
                            }}
                            role={role}
                        />
                    ) }
                </Modal>
            </div>
        </div>
    );
}

export default Connections;
