export const updateLabsAction = (data) => ({
    type: 'labs/UPDATE_STATE',
    labs: data.content,
    pagination: {
        number: data.number,
        totalPages: data.totalPages,
        first: data.first,
        last: data.last,
    },
});

export const updateAllLabsAction = (data) => ({
    type: 'labs/UPDATE_STATE',
    allLabs: data.content,
    pagination: {
        number: data.number,
        totalPages: data.totalPages,
        first: data.first,
        last: data.last,
    },
});

export const filterUsersAction = (newFilter) => ({
    type: 'users/UPDATE_FILTER',
    ...newFilter,
});

export const updateConnectionsAction = (data) => {
    const compare = (a, b) => a.name.toLowerCase() < b.name.toLowerCase();
    const sortedConnections = data.content.sort((a, b) => {
        if (a.clinic.name === b.clinic.name) {
            if (compare(b.lab, a.lab)) return 1;
            if (compare(a.lab, b.lab)) return -1;
            return 0;
        }
        if (compare(b.clinic, a.clinic)) return 1;
        if (compare(a.clinic, b.clinic)) return -1;
        return 0;
    });
    return {
        type: 'connections/UPDATE_STATE',
        connections: sortedConnections,
        pagination: {
            number: data.number,
            totalPages: data.totalPages,
            first: data.first,
            last: data.last,
        },
    };
};
