import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import { initializeGlobalStore } from './redux/reducers/global.reducer';
import App from './App';
import './assets/scss/index.scss';
import * as serviceWorker from './serviceWorker';

// initialize app wide store
const store = configureStore();

// initlize and load global data on load time
store.dispatch(initializeGlobalStore());

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root'),
);

serviceWorker.unregister();
