import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import { connect } from 'react-redux';
import {
    Row,
    Column,
    Spinner,
} from '../../..';
import { http } from '../../../../helpers';
import './styles.scss';

class Download extends Component {
    constructor() {
        super();
        this.state = {
            fetchingPdf: false,
            fetchingZip: false,
        };
    }

    getCaseStatus = async (id) => http.get(`/case-api/cases/${id}/status`)
        .then(({ data: { status } }) => status)
        .catch((error) => console.warn(error));

    updateCaseStatusToAccepted = async (id) => http.post(`/case-api/cases/${id}/status/accepted`)
        .then(({ data: { status } }) => status)
        .catch((error) => console.warn(error));

    handleRXDownload = (id, id4, uri, canUpdateCaseStatus) => {
        const { updateCaseStatus } = this.props;
        this.setState({ fetchingPdf: true });
        http.getBlob(uri, 'application/pdf')
            .then(async (response) => {
                const blob = new Blob([response.data], {
                    type: 'application/pdf',
                });
                FileSaver.saveAs(blob, `prescription_${id4}.pdf`);
                if (canUpdateCaseStatus) {
                    await this.updateCaseStatusToAccepted(id);
                    const status = await this.getCaseStatus(id);
                    updateCaseStatus(id, status); // update status of the case ties to this rx
                }
                this.setState({ fetchingPdf: false });
            })
            .catch(() => console.warn('oh no!'));
    };

    handleZIPDownload = (uri, id4) => {
        this.setState({ fetchingZip: true });
        http.getBlob(uri, 'application/zip')
            .then((response) => {
                const blob = new Blob([response.data], {
                    type: 'application/zip',
                });
                FileSaver.saveAs(blob, `scan_${id4}.zip`);
                this.setState({ fetchingZip: false });
            })
            .catch(() => console.warn('oh no!'));
    };

    render() {
        const { id, id4, prescriptionUrl, scanZipUrl, canDownloadPrescription, canUpdateCaseStatus, canDownloadSTL } = this.props;
        const { fetchingZip, fetchingPdf } = this.state;

        return (
            <div className="column-container download">
                <Row>
                    <Column span="12" divider={false}>
                        {
                            canDownloadPrescription
                            && (
                                <button type="button" className="btn btn-icon full-width" onClick={() => this.handleRXDownload(id, id4, prescriptionUrl, canUpdateCaseStatus)} disabled={fetchingPdf}>
                                    <i className="material-icons">cloud_download</i>
                                    {
                                        fetchingPdf
                                            ? <Spinner type="inline-bigger" />
                                            : <> Prescription Download </>
                                    }
                                </button>
                            )
                        }
                        {
                            canDownloadSTL
                            && (
                                <button type="button" className="btn btn-icon full-width" onClick={() => this.handleZIPDownload(scanZipUrl, id4)} disabled={fetchingZip}>
                                    <i className="material-icons">cloud_download</i>
                                    {
                                        fetchingZip
                                            ? <Spinner type="inline-bigger" />
                                            : <> Scans Download </>
                                    }
                                </button>
                            )
                        }
                    </Column>
                </Row>
            </div>
        );
    }
}

Download.propTypes = {
    id: PropTypes.string.isRequired,
    id4: PropTypes.string.isRequired,
    prescriptionUrl: PropTypes.string,
    scanZipUrl: PropTypes.string,
    updateCaseStatus: PropTypes.func.isRequired,
    canDownloadPrescription: PropTypes.bool.isRequired,
    canUpdateCaseStatus: PropTypes.bool.isRequired,
    canDownloadSTL: PropTypes.bool.isRequired,
};

Download.defaultProps = {
    prescriptionUrl: '',
    scanZipUrl: '',
};

const mapDispatchToProps = (dispatch) => ({
    updateCaseStatus: (id, status) => dispatch({ type: 'UPDATE_CASE_STATUS', id, status }),
});

export default connect(null, mapDispatchToProps)(Download);
