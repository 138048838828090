import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Line from './Line';

const BorderLinearProgress = styled(LinearProgress)(
    ({ background }) => ({
        height: 50,
        borderRadius: '5px',
        border: '1px dotted grey',
        backgroundColor: 'white',
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: background,
        },
    }),
);

function NewScannerWidget({ remaining, total }) {
    const perc = ((total - remaining) / total) * 100;
    return (
        <Box
            display="flex"
            className="widget-container"
            sx={{ mb: 10, mt: 10, position: 'relative' }}
        >
            <Line remaining={remaining} total={total} perc={perc} />
            <Box sx={{ width: '100%', paddingBottom: '20px' }}>
                <Grid container justifyContent="space-between">
                    <Grid item className="text first">0</Grid>
                    <Grid item className="text first">{total}</Grid>
                </Grid>
                <BorderLinearProgress
                    variant="determinate"
                    value={perc}
                    background="#385592"
                />
            </Box>
        </Box>
    );
}

NewScannerWidget.propTypes = {
    remaining: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
};

export default NewScannerWidget;
