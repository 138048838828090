import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Modal,
    AddConnectionForm,
} from '..';

class ConnectionsGroup extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
        };
    }

    render() {
        const { showModal } = this.state;
        return (
            <div className="connections-group-container">
                <div className="btn-group">
                    <Link to="/dashboard/connections">
                        <button type="button" className="btn btn-icon">
                            <i className="material-icons">people</i>
                            Connections
                        </button>
                    </Link>
                    <button type="button" className="btn btn-dark" onClick={() => this.setState({ showModal: true })}>
                        <i className="material-icons">add</i>
                    </button>
                </div>
                <Modal show={showModal}>
                    <i className="material-icons" role="presentation" onClick={() => this.setState({ showModal: false })}>close</i>
                    <AddConnectionForm closeModalHandler={() => this.setState({ showModal: false })} />
                </Modal>
            </div>
        );
    }
}

export default ConnectionsGroup;
