/* eslint-disable react/jsx-indent-props */

import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CaseDetail from '../CaseDetail';
import {
    TableRow,
    TableData,
    TableRowDrop,
    TableDataWrapper,
} from '../..';

const formatDate = (d) => (d ? moment(d).format('D MMM YYYY') : '-');

const trimId = (id) => (id.substring(0, 4));

function Case({
    id,
    status,
    caseGroups,
    patientFirstName,
    patientLastName,
    doctorName,
    clinicName,
    labName,
    createdOn,
    receivedOn,
    dueOn,
    upperStlImageUrl,
    lowerStlImageUrl,
    fullStlImageUrl,
    upperPlyImageUrl,
    lowerPlyImageUrl,
    fullPlyImageUrl,
    prescriptionUrl,
    scanZipUrl,
    thumbnailUrl,
    template,
    instructions,
    labId,
    scans,
}) {
    const [open, setOpen] = useState(false);
    const toggleHandler = () => {
        setOpen(!open);
    };

    // Determine table columns
    const { scope } = localStorage;
    const scopeArray = (scope || '').split(' ');
    const hasAccess = (rule) => scopeArray.includes(rule);
    const canViewClinicsColumn = hasAccess('cases.display.clinics:read');
    const canViewLabsColumn = hasAccess('cases.display.labs:read');
    let cols;
    const patientName = (`${patientFirstName || ''} ${patientLastName || ''}`).trim();

    if (canViewClinicsColumn && canViewLabsColumn) {
        cols = (
            <div>
                <TableData data={status} span="1" />
                <TableData data={patientName} span="2" />
                <TableData data={doctorName} span="2" />
                <TableData data={clinicName} span="2" />
                <TableData data={labName} span="1" />
                <TableData data={trimId(id)} span="1" isCapitalized={false} />
                <TableData data={formatDate(createdOn)} span="1" />
                <TableData data={formatDate(receivedOn)} span="1" />
                <TableData data={formatDate(dueOn)} span="1" />
            </div>
        );
    } else if (canViewClinicsColumn && !canViewLabsColumn) {
        cols = (
            <div>
                <TableData data={status} span="1" />
                <TableData data={patientName} span="3" />
                <TableData data={doctorName} span="2" />
                <TableData data={clinicName} span="2" />
                <TableData data={trimId(id)} span="1" isCapitalized={false} />
                <TableData data={formatDate(createdOn)} span="1" />
                <TableData data={formatDate(receivedOn)} span="1" />
                <TableData data={formatDate(dueOn)} span="1" />
            </div>
        );
    } else if (!canViewClinicsColumn && canViewLabsColumn) {
        cols = (
            <div>
                <TableData data={status} span="1" />
                <TableData data={patientName} span="3" />
                <TableData data={doctorName} span="2" />
                <TableData data={labName} span="2" />
                <TableData data={trimId(id)} span="1" isCapitalized={false} />
                <TableData data={formatDate(createdOn)} span="1" />
                <TableData data={formatDate(receivedOn)} span="1" />
                <TableData data={formatDate(dueOn)} span="1" />
            </div>
        );
    } else {
        cols = (
            <div>
                <TableData data={status} span="1" />
                <TableData data={patientName} span="4" />
                <TableData data={doctorName} span="3" />
                <TableData data={trimId(id)} span="1" isCapitalized={false} />
                <TableData data={formatDate(createdOn)} span="1" />
                <TableData data={formatDate(receivedOn)} span="1" />
                <TableData data={formatDate(dueOn)} span="1" />
            </div>
        );
    }

    return (
        <TableRow>
            <TableDataWrapper onClick={toggleHandler}>
                <i className={`material-icons ${open ? 'rotate' : ''}`}>keyboard_arrow_right</i>
                {
                    status === 'Submitted' && <i className="material-icons status processing">timelapse</i>
                }
                {
                    status === 'Accepted' && <i className="material-icons status completed">check_circle_outline</i>
                }
                { cols }
            </TableDataWrapper>
            <TableRowDrop open={open} openHeight={template === 'Clear Aligner' ? null : '500px'}>
                {
                    open && (
                        <CaseDetail
                            id={id}
                            groups={caseGroups}
                            upperStlImageUrl={upperStlImageUrl}
                            lowerStlImageUrl={lowerStlImageUrl}
                            fullStlImageUrl={fullStlImageUrl}
                            upperPlyImageUrl={upperPlyImageUrl}
                            lowerPlyImageUrl={lowerPlyImageUrl}
                            fullPlyImageUrl={fullPlyImageUrl}
                            prescriptionUrl={prescriptionUrl}
                            thumbnailUrl={thumbnailUrl}
                            scanZipUrl={scanZipUrl}
                            template={template}
                            instructions={instructions}
                            labId={labId}
                            status={status}
                            scans={scans}
                        />
                    )
                }
            </TableRowDrop>
        </TableRow>
    );
}

Case.propTypes = {
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    caseGroups: PropTypes.arrayOf(
        PropTypes.object.isRequired,
    ),
    patientFirstName: PropTypes.string,
    patientLastName: PropTypes.string,
    doctorName: PropTypes.string,
    clinicName: PropTypes.string,
    labName: PropTypes.string,
    createdOn: PropTypes.string,
    receivedOn: PropTypes.string,
    dueOn: PropTypes.string,
    upperStlImageUrl: PropTypes.string,
    lowerStlImageUrl: PropTypes.string,
    fullStlImageUrl: PropTypes.string,
    upperPlyImageUrl: PropTypes.string,
    lowerPlyImageUrl: PropTypes.string,
    fullPlyImageUrl: PropTypes.string,
    prescriptionUrl: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    scanZipUrl: PropTypes.string,
    template: PropTypes.string,
    instructions: PropTypes.string,
    labId: PropTypes.string,
    scans: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        dentalCaseId: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
};

Case.defaultProps = {
    patientFirstName: '',
    patientLastName: '',
    doctorName: '',
    clinicName: '',
    labName: '',
    createdOn: '',
    receivedOn: '',
    dueOn: '',
    upperStlImageUrl: '',
    lowerStlImageUrl: '',
    fullStlImageUrl: '',
    upperPlyImageUrl: '',
    lowerPlyImageUrl: '',
    fullPlyImageUrl: '',
    prescriptionUrl: '',
    thumbnailUrl: '',
    scanZipUrl: '',
    template: '',
    instructions: '',
    labId: '',
    caseGroups: [],
};

export default Case;
