import React from 'react';
import PropTypes from 'prop-types';

function ModalHeader({ children, closeHandler }) {
    return (
        <div className="modal-header">
            <span>
                {
                    children
                }
            </span>
            <i className="material-icons" role="presentation" onClick={closeHandler}>close</i>
        </div>
    );
}

ModalHeader.propTypes = {
    children: PropTypes.element,
    closeHandler: PropTypes.func.isRequired,
};

ModalHeader.defaultProps = {
    children: null,
};

export default ModalHeader;
