/* eslint-disable no-param-reassign */

const initialState = [];

export const updateCaseLabAction = (caseId, labId, labName) => ({
    type: 'UPDATE_CASE_LAB',
    caseId,
    labId,
    labName,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case 'INITIALIZE_CASES':
            return action.cases;
        case 'UPDATE_CASE_STATUS':
            return state.map((item) => {
                if (item.id === action.id) {
                    item.status = action.status;
                    return item;
                }
                return item;
            });
        case 'UPDATE_CASE_LAB':
            return state.map((item) => {
                if (item.id === action.caseId) {
                    item.labId = action.labId;
                    item.labName = action.labName === 'None' ? '' : action.labName;
                    return item;
                }
                return item;
            });
        default:
            return state;
    }
};
