import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function TableData({ data, span, isCapitalized, dataType }) {
    const formatPhone = (phn) => {
        if (!phn) return '';
        return `(${phn.slice(0, 3)}) ${phn.slice(3, 6)} ${phn.slice(6, 10)}`;
    };
    const tableDataCharacterClass = isCapitalized
        ? 'table-data-capitalized' : 'table-data-lowercase';
    return (
        <div className={`table-data ${tableDataCharacterClass} span-${span}`}>
            <span>
                {dataType === 'phone' ? formatPhone(data) : data}
            </span>
        </div>
    );
}

TableData.propTypes = {
    data: PropTypes.node.isRequired,
    span: PropTypes.string.isRequired,
    isCapitalized: PropTypes.bool,
    dataType: PropTypes.string,
};

TableData.defaultProps = {
    isCapitalized: true,
    dataType: '',
};

export default TableData;
