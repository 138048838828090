import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import { Input } from '..';
import './styles.scss';

function Search({ onChange, label }) {
    const onUpdateSearch = (evt) => onChange(evt.target.value);
    return (
        <Input
            onChange={onUpdateSearch}
            label="Search"
            variant="outlined"
            placeholder={label}
            InputProps={{ endAdornment: <SearchIcon /> }}
        />
    );
}

Search.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};

export default Search;
