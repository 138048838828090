import './styles.scss';
import { Alert, Button, Grid } from '@mui/material';
import { useState } from 'react';
import { Header, Input } from '../../components';
import { http } from '../../helpers';

function ChangePassword() {
    const [confirmPassword, setConfirmPassword] = useState('');
    const [fail, setFail] = useState(false);
    const [password, setPassword] = useState('');
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Something went wrong');

    const onSubmit = () => {
        const url = '/case-api/profile/password';
        const body = { password };

        return http
            .post(url, body)
            .then(() => {
                setSuccess(true);
                setFail(false);
                setPassword('');
                setConfirmPassword('');
            })
            .catch(({ request }) => {
                const response = JSON.parse(request?.response);
                const error = response?.error;
                if (error) setErrorMessage(error);
                setSuccess(false);
                setFail(true);
            });
    };

    return (
        <>
            <Header label="Change Password" />
            <main className="change-password-container">
                <Grid spacing={4} container direction="column" alignItems="center">
                    <Grid
                        className="form-field"
                        item
                        style={{ height: '50px', paddingTop: '0px' }}
                    >
                        {success && (
                            <Alert>
                                Success! Password Changed
                            </Alert>
                        )}
                        {fail && (
                            <Alert severity="error">
                                { errorMessage }
                            </Alert>
                        )}
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Password"
                            variant="outlined"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Confirm Password"
                            variant="outlined"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        {password !== confirmPassword && <div style={{ color: 'red', height: '20px' }}>Passwords do not match</div>}
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={onSubmit}
                            disabled={!password || !confirmPassword || password !== confirmPassword}
                            variant="outlined"
                            style={{ border: '1px solid black', color: 'black' }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </main>
        </>
    );
}
export default ChangePassword;
