import { http } from '../../helpers';
import { fetchUsers } from './fetchUsers';

export function deleteUser(id) {
    return (dispatch) => {
        const relativeUrl = `/case-api/users/${id}`;
        http.delete(relativeUrl)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(fetchUsers());
                }
            })
            .catch((error) => {
                console.warn(error);
            });
    };
}
