import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import notfound from '../../assets/images/not_found.png';

function NotFound() {
    return (
        <div>
            <div className="center">
                <img alt="not found" src={notfound} />
            </div>
            <div className="center">
                <div id="info">
                    <h1>This page could not be found</h1>
                    <h3>
                        <Link to="/">Go Back</Link>
                    </h3>
                </div>
            </div>
        </div>
    );
}

export default NotFound;
