import React, { Component } from 'react';
import { http } from '../../../helpers';
import { Spinner, Row, Column } from '../../../components';
import './style.scss';

class PaymentTab extends Component {
    constructor() {
        super();
        this.state = {
            firstName: null,
            lastName: null,
            company: null,
            email: null,
            phoneNumber: null,
            address: null,
            city: null,
            state: null,
            zip: null,
            country: null,
            cardNumber: null,
            expirationMonth: '',
            expirationYear: '',
            submitting: false,
            fetching: false,
            years: [],
            success: false,
            error: false,
            errorMessage: '',
        };
    }

    componentDidMount() {
        this.generateYears();
        this.setState({ fetching: true });
        http.get('/case-api/customers/current')
            .then(({ data }) => this.onSuccess(data))
            .catch((error) => console.warn(error));
    }

    onSuccess = ({ firstName, lastName, company, address, city, state, zip, country, email, phoneNumber, cardNumber }) => {
        this.setState({ fetching: false, firstName, lastName, company, address, city, state, zip, country, email, phoneNumber, cardNumber });
    };

    changeHandler = ({ name, value }) => {
        this.setState({ [name]: value });
    };

    formIsValid = () => {
        const { firstName, lastName, company, address, city, state, zip, country, email, phoneNumber, cardNumber, expirationMonth, expirationYear } = this.state;
        return firstName && lastName && company && address && city && state && zip && country && email && phoneNumber && cardNumber && expirationMonth && expirationYear;
    };

    submitHandler = (event) => {
        event.preventDefault();
        const { firstName, lastName, company, address, city, state, zip, country, email, phoneNumber, cardNumber, expirationMonth, expirationYear } = this.state;
        http.post('/case-api/customers/current', { firstName, lastName, company, address, city, state, zip, country, email, phoneNumber, cardNumber, expirationDate: expirationMonth + expirationYear })
            .then(() => this.onSubmitSuccess())
            .catch((error) => this.onSubmitError(error));
    };

    onSubmitSuccess = () => {
        this.setState({ submitting: false, success: true, error: false });
    };

    onSubmitError = (error) => {
        this.setState({ success: false, error: true, errorMessage: error.message });
    };

    generateYears = () => {
        const date = new Date();
        const year = date.getFullYear();
        const result = [...new Array(10)].map((x, index) => ({ year: (year + index).toString(), value: (+year.toString().slice(0, 2) + index).toString() }));
        this.setState({ years: result });
    };

    render() {
        const { firstName, lastName, company, address, city, state, zip, country, email, phoneNumber, cardNumber, expirationMonth, expirationYear, fetching, submitting, years, success, error, errorMessage } = this.state;
        const form = (
            <form onSubmit={this.submitHandler}>
                <div className="input-group">
                    <div className="input-group-column span-6">
                        <label htmlFor="firstName">
                            *First Name
                            <input type="text" name="firstName" placeholder="First Name" defaultValue={firstName} onChange={(e) => this.changeHandler(e.target)} />
                        </label>
                    </div>
                    <div className="input-group-column span-6">
                        <label htmlFor="lastName">
                            *Last Name
                            <input type="text" name="lastName" placeholder="Last Name" defaultValue={lastName} onChange={(e) => this.changeHandler(e.target)} />
                        </label>
                    </div>
                </div>
                <label htmlFor="email">
                    *Billing Email
                    <input type="email" name="email" placeholder="example@gmail.com" defaultValue={email} onChange={(e) => this.changeHandler(e.target)} />
                </label>
                <label htmlFor="company">
                    *Company
                    <input type="text" name="company" placeholder="company" defaultValue={company} onChange={(e) => this.changeHandler(e.target)} />
                </label>
                <label htmlFor="phoneNumber">
                    *Phone Number
                    <input type="text" name="phoneNumber" placeholder="Phone Number" defaultValue={phoneNumber} onChange={(e) => this.changeHandler(e.target)} />
                </label>
                <label htmlFor="address">
                    *Address
                    <input type="text" name="address" placeholder="Address" defaultValue={address} onChange={(e) => this.changeHandler(e.target)} />
                </label>
                <div className="input-group">
                    <div className="input-group-column span-5">
                        <label htmlFor="city">
                            *City
                            <input type="text" name="city" placeholder="City" defaultValue={city} onChange={(e) => this.changeHandler(e.target)} />
                        </label>
                    </div>
                    <div className="input-group-column span-5">
                        <label htmlFor="state">
                            *State
                            <input type="text" name="state" placeholder="State" defaultValue={state} onChange={(e) => this.changeHandler(e.target)} />
                        </label>
                    </div>
                    <div className="input-group-column span-2">
                        <label htmlFor="zip">
                            *Zip
                            <input type="text" name="zip" placeholder="Zip" defaultValue={zip} onChange={(e) => this.changeHandler(e.target)} />
                        </label>
                    </div>
                </div>
                <div className="input-group">
                    <div className="input-group-column span-6">
                        <label htmlFor="cardNumber">
                            *Card Number
                            <input type="text" name="cardNumber" placeholder="Card Number" maxLength="16" defaultValue={cardNumber} onChange={(e) => this.changeHandler(e.target)} />
                        </label>
                    </div>
                    <div className="input-group-column span-3">
                        <label htmlFor="expirationDate">
                            *Expiration Month
                            <select name="expirationMonth" value={expirationMonth} onChange={(e) => this.changeHandler(e.target)}>
                                <option value="" disabled>Select month</option>
                                <option value="01">01</option>
                                <option value="02">02</option>
                                <option value="03">03</option>
                                <option value="04">04</option>
                                <option value="05">05</option>
                                <option value="06">06</option>
                                <option value="07">07</option>
                                <option value="08">08</option>
                                <option value="09">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                        </label>
                    </div>
                    <div className="input-group-column span-3">
                        <label htmlFor="expirationYear">
                            *Expiration Year
                            <select name="expirationYear" value={expirationYear} onChange={(e) => this.changeHandler(e.target)}>
                                <option value="" disabled>Select year</option>
                                {
                                    years.map((item) => <option value={item.value} key={item.value}>{item.year}</option>)
                                }
                            </select>
                        </label>
                    </div>
                </div>
                <label htmlFor="country">
                    *Country
                    <input type="text" name="country" placeholder="Country" defaultValue={country} onChange={(e) => this.changeHandler(e.target)} />
                </label>
                <Row>
                    <Column span="11" styles="text-align-right" divider={false}>
                        {
                            success && <span className="text-success text-bold response-message">Successfully saved</span>
                        }
                        {
                            error && <span className="text-red text-bold response-message">{ errorMessage }</span>
                        }
                    </Column>
                    <Column span="1" divider={false}>
                        <button className="btn btn-dark" type="submit" disabled={!this.formIsValid() || submitting}>
                            Submit
                            {
                                submitting && <Spinner type="inline" />
                            }
                        </button>
                    </Column>
                </Row>
            </form>
        );

        const spinner = (
            <Spinner type="page" />
        );

        return (
            <div className="payment-tab">
                {
                    fetching ? spinner : form
                }
            </div>
        );
    }
}

export default PaymentTab;
