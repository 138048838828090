import PropTypes from 'prop-types';
import { Autocomplete } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { http } from '../../helpers';
import { Input } from '../index';

function ClinicPicker({ selectedClinic, onSelectClinic, disabled, required, errorText }) {
    const dispatch = useDispatch();
    const [clinics, setClinics] = useState([]);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(null);

    const getClinics = () => {
        // TODO: check store before call
        // TODO: implement search system if clinic number ever goes over 1000
        if (pageCount && page >= pageCount) return;
        const url = `/case-api/clinics?size=1000&page=${page}&sort=name,id&archived=false`;
        http
            .get(url)
            .then(({ data: { content, totalPages } }) => {
                setClinics(content);
                setPage(page + 1);
                setPageCount(totalPages);
                dispatch({
                    type: 'clinics/UPDATE_STATE',
                    allClinics: content,
                });
            })
            .catch((error) => console.warn(error));
    };

    const handleScroll = (event) => {
        const listboxNode = event.currentTarget;
        const x = listboxNode.scrollTop + listboxNode.clientHeight;

        if (listboxNode.scrollHeight - x < 1) {
            getClinics();
        }
    };

    useEffect(() => {
        getClinics();
    }, []);

    return (
        <Autocomplete
            disabled={disabled}
            className="form-field"
            options={clinics}
            ListboxProps={{ onScroll: handleScroll }}
            renderInput={(props) => (
                <Input {...props} error={errorText} helperText={errorText} label={`Clinics ${required ? '*' : ''}`} />
            )}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.name}
                </li>
            )}
            getOptionLabel={(option) => option?.name || ''}
            value={clinics.find((c) => c.id === selectedClinic) || null}
            onChange={(event, newValue) => {
                onSelectClinic(newValue?.id || '');
            }}
        />
    );
}

ClinicPicker.propTypes = {
    onSelectClinic: PropTypes.func,
    selectedClinic: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    errorText: PropTypes.string,
};

ClinicPicker.defaultProps = {
    onSelectClinic: () => {},
    selectedClinic: '',
    disabled: false,
    required: false,
    errorText: '',
};

export default ClinicPicker;
