import './styles.scss';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import InnerImageZoom from 'react-inner-image-zoom';
import { Grid, Modal, Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { http } from '../../../../helpers';

function ClickableImage({ blob, type }) {
    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
    };

    return (
        <>
            <div role="presentation" onClick={openModal}>
                <img
                    className="clear-thumbnail"
                    src={blob}
                    alt={type}
                />
            </div>
            <Modal open={showModal} onClose={closeModal}>
                <Box sx={style}>
                    <div className="modal-close-button">
                        <IconButton onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <InnerImageZoom src={blob} zoomScale={3.5} />
                </Box>
            </Modal>
        </>
    );
}

ClickableImage.propTypes = {
    blob: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

function Thumbnail({ url, type }) {
    const [blob, setBlob] = useState(null);

    useEffect(() => {
        if (blob) return;

        const relativeUrl = `${url}?type=${type}`;

        http.getBlob(relativeUrl, 'image/png')
            .then((response) => {
                const image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                setBlob(`data:${response.headers['content-type'].toLowerCase()};base64,${image}`);
            })
            .catch((error) => {
                console.log(error);
            });
    });

    return blob
        ? <ClickableImage blob={blob} type={type} />
        : <div className="no-image">No Image</div>;
}

Thumbnail.propTypes = {
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

function ClearAlignerThumbnails({ url }) {
    const types = ['side2', 'front', 'side1', 'top', 'back', 'bottom'];
    return (
        <Grid container alignItems="center">
            {
                types.map((type) => (
                    <Grid item key={type} xs={4} style={{ textAlign: 'center' }}>
                        <Thumbnail url={url} type={type} />
                    </Grid>
                ))
            }
        </Grid>
    );
}

ClearAlignerThumbnails.propTypes = {
    url: PropTypes.string.isRequired,
};
export default ClearAlignerThumbnails;
