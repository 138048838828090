/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */

import './styles.scss';
import { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'query-string';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../components';
import logo from '../../assets/images/ori_logo.png';
import { updateRoleAction } from '../../redux/reducers/global.reducer';
import { getRole, updateLocalStorage } from '../../helpers';
import config from '../../config';

function Login() {
    const dispatch = useDispatch();
    const oauthToken = useSelector((state) => state.global.oauthToken);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [requesting, setRequesting] = useState(false);
    const [error, setError] = useState('');
    const [info, setInfo] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [showEmailInput, setShowEmailInput] = useState(false);

    const onSetUsername = ({ target }) => {
        setUsername(target.value);
    };
    const onSetPassword = ({ target }) => {
        setPassword(target.value);
    };

    const onSuccess = (response) => {
        setRequesting(false);
        setLoggedIn(true);
        updateLocalStorage(response);
        dispatch(updateRoleAction(getRole()));
    };

    const onError = () => {
        setRequesting(false);
        setError('Your username or password is incorrect.');
    };
    const onLogin = () => {
        localStorage.clear();
        const requestBody = { username, password, grant_type: 'password' };
        const headers = { headers: { Authorization: `Basic ${oauthToken}` } };
        setRequesting(true);
        setError('');
        setInfo('');
        axios
            .post(
                `${config.openIdUrl}/protocol/openid-connect/token`,
                qs.stringify(requestBody),
                headers,
            )
            .then((response) => onSuccess(response))
            .catch((err) => onError(err));
    };
    const onRequestPasswordReset = () => {
        setRequesting(true);
        fetch(`${config.apiUrl}/case-api/profile/forgot-password`, {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                setRequesting(false);
                if (!response.ok) {
                    setInfo('');
                    setError('Error requesting password reset');
                } else {
                    setError('');
                    setInfo(`If ${email} represents an existing account, you will get an email with instructions on how to reset your password shortly. You can now close this window.`);
                }
            });
    };
    const onSubmitHandler = (event) => {
        event.preventDefault();
        if (showEmailInput) onRequestPasswordReset();
        else onLogin();
    };

    const isValid = () => requesting || (!showEmailInput && (!username.length || !password.length)) || (showEmailInput && !email);

    const onShowEmailInput = () => {
        setEmail('');
        setShowEmailInput(true);
    };
    const onHideEmailInput = () => {
        setError('');
        setInfo('');
        setShowEmailInput(false);
    };
    const onSetEmail = (evt) => setEmail(evt.target.value);

    useEffect(() => {
        localStorage.clear();
    }, []);

    return (
        <div className="flex-container">
            {loggedIn && <Navigate to="/cases" replace />}
            <div className="login-container">
                <img src={logo} alt="Ori Dental Scanner logo" />
                <h6>Ori</h6>
                <p className="text-center text-red text-bold">
                    {
                        error
                    }
                </p>
                <p className="text-center text-green">
                    {
                        info
                    }
                </p>
                <form onSubmit={onSubmitHandler}>
                    { showEmailInput ? (
                        <div>
                            <label htmlFor="email">
                                Email or Username
                                <input name="email" value={email} type="text" placeholder="Email or Username" onChange={onSetEmail} disabled={requesting} />
                            </label>
                            <button
                                type="button"
                                className="forgot-password clickable"
                                onClick={onHideEmailInput}
                            >
                                Back
                            </button>
                        </div>
                    ) : (
                        <div>
                            <label htmlFor="username">
                                Username
                                <input name="username" type="text" placeholder="Username" onChange={onSetUsername} disabled={requesting} />
                            </label>
                            <label htmlFor="Password">
                                Password
                                <input name="password" type="password" placeholder="Password" onChange={onSetPassword} disabled={requesting} />
                            </label>
                            <button
                                type="button"
                                className="forgot-password clickable"
                                onClick={onShowEmailInput}
                            >
                                Forgot password?
                            </button>
                        </div>
                    )}
                    <button className="btn btn-dark" type="submit" disabled={isValid()}>
                        Submit
                        {
                            requesting && <Spinner type="inline" />
                        }
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Login;
