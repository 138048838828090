import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function Row({ children, styles }) {
    return (
        <div className={`row ${styles}`}>
            {
                children
            }
        </div>
    );
}

Row.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    styles: PropTypes.string,
};

Row.defaultProps = {
    styles: '',
};

export default Row;
