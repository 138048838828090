import axios from 'axios';
import qs from 'query-string';
import config from '../config';

export default function getRefreshedToken() {
    const headers = { headers: {
        Authorization: 'Basic d2ViYXBwOg==',
        'Content-Type': 'application/x-www-form-urlencoded',
    } };
    const payload = qs.stringify({
        grant_type: 'refresh_token',
        client_id: 'webapp',
        refresh_token: localStorage.getItem('refreshToken'),
    });
    return axios.post(
        `${config.openIdUrl}/protocol/openid-connect/token`,
        payload,
        headers,
    );
}
