import PropTypes from 'prop-types';
import { Autocomplete } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { http } from '../../helpers';
import { Input } from '../index';

function LabPicker({ selectedLab, onSelectLab, disabled, includeNone, returnObject, required, errorText }) {
    const dispatch = useDispatch();
    const [labs, setLabs] = useState([]);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [currentLab, setCurrentLab] = useState('');

    const getLabs = () => {
        // TODO: check store before call
        if (pageCount && page >= pageCount) return;
        const url = `/case-api/labs?size=1000&page=${page}&sort=name,id&archived=false&search=${inputValue}`;
        http
            .get(url)
            .then(({ data: { content, totalPages } }) => {
                if (inputValue) setLabs(content);
                else setLabs([...labs, ...content]);
                setPage(page + 1);
                setPageCount(totalPages);
                dispatch({
                    type: 'labs/UPDATE_STATE',
                    allLabs: content,
                });
            })
            .catch((error) => console.warn(error));
    };

    const handleScroll = (event) => {
        const listboxNode = event.currentTarget;
        const x = listboxNode.scrollTop + listboxNode.clientHeight;

        if (listboxNode.scrollHeight - x < 1) {
            getLabs();
        }
    };

    const getSelectedLab = async () => {
        if (!selectedLab) return null;

        const foundLab = labs.find((c) => c.id === selectedLab);
        if (foundLab) return foundLab;

        if (selectedLab === 'none') return { id: 'none', name: 'None' };

        const url = `/case-api/labs/${selectedLab}`;
        return http.get(url).then((r) => r.data).catch(console.error);
    };

    const onSetInputValue = (value) => {
        setInputValue(value);
        getLabs();
    };

    useEffect(() => {
        getSelectedLab()
            .then(setCurrentLab);
    }, [selectedLab]);

    useEffect(() => {
        getLabs();
    }, []);

    return (
        <Autocomplete
            disabled={disabled}
            className="form-field"
            options={includeNone ? [{ id: 'none', name: 'None' }, ...labs] : labs}
            ListboxProps={{ onScroll: handleScroll }}
            renderInput={(props) => (
                <Input {...props} error={errorText} helperText={errorText} label={`Labs ${required ? '*' : ''}`} />
            )}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.name}
                </li>
            )}
            getOptionLabel={(option) => option?.name || ''}
            value={currentLab}
            onChange={(event, newValue) => {
                if (returnObject) onSelectLab(newValue);
                else onSelectLab(newValue?.id || '');
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                onSetInputValue(newInputValue);
            }}
        />
    );
}

LabPicker.propTypes = {
    selectedLab: PropTypes.string,
    onSelectLab: PropTypes.func,
    disabled: PropTypes.bool,
    includeNone: PropTypes.bool,
    returnObject: PropTypes.bool,
    required: PropTypes.bool,
    errorText: PropTypes.string,
};

LabPicker.defaultProps = {
    selectedLab: '',
    onSelectLab: () => {},
    disabled: false,
    includeNone: false,
    returnObject: false,
    required: false,
    errorText: '',
};

export default LabPicker;
