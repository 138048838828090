import { http } from '../../helpers';
import { fetchClinics } from './fetchClinics';

export function updateClinic(payload) {
    return (dispatch) => {
        const relativeUrl = `/case-api/clinics/${payload.id}`;
        http.put(relativeUrl, payload)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(fetchClinics());
                }
            })
            .catch((error) => {
                console.warn(error);
            });
    };
}
