import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function NavItem({ item }) {
    // eslint-disable-next-line react/prop-types
    const Icon = item.icon;

    const dispatch = useDispatch();
    const closeDrawer = () => dispatch({ type: 'SET_MENU', opened: false });

    return (
        <MenuItem
            component={Link}
            to={item.url}
            style={{ height: '60px' }}
            onClick={closeDrawer}
        >
            <ListItemIcon>
                <Icon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{item.title}</ListItemText>
        </MenuItem>
    );
}

NavItem.propTypes = {
    item: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
};

export default NavItem;
