import './styles.scss';
import PropTypes from 'prop-types';
import { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Row, Column, ConfirmatinModal } from '../../../components';

function Connection({
    props: {
        name,
        email,
        officePhone,
        cellPhone,
        company,
    },
    onDeleteConnection,
}) {
    const [showConfirm, setShowConfirm] = useState(false);
    const onShowConfirmation = () => setShowConfirm(true);
    const onCloseModal = () => setShowConfirm(false);
    const [menuStyles, setMenuStyles] = useState({ visibility: 'hidden' });
    const hasDeletePermission = localStorage?.scope?.indexOf('connections:delete') > -1;

    const onShowMenu = () => setMenuStyles({ visibility: 'visible' });
    const hideMenu = () => setMenuStyles({ visibility: 'hidden' });
    return (
        <div className="connection" onMouseOver={onShowMenu} onFocus={onShowMenu} onMouseLeave={hideMenu}>
            { hasDeletePermission ? (
                <div className="delete-connection-btn" style={menuStyles}>
                    <ConnectionMenu
                        onShowConfirmation={onShowConfirmation}
                    />
                </div>
            ) : ''}
            <div className="connection-content">
                <div className="connection-body">
                    <Row>
                        <Column span="12" styles="no-padding" divider={false}>
                            <Row>
                                <div className="connection-card-heading">
                                    <i style={{ gridArea: 'icon' }} className="material-icons">business</i>
                                    <div style={{ gridArea: 'name' }} className="connection-header">{ name }</div>
                                    <span style={{ gridArea: 'company' }} className="connection-subheader">{ company }</span>
                                </div>
                            </Row>
                            <Row>
                                <i className="material-icons">mail_outline</i>
                                <span>{ email }</span>
                            </Row>
                            <Row>
                                <i className="material-icons">local_phone</i>
                                <small>Office</small>
                                <span>{ officePhone }</span>
                            </Row>
                            <Row>
                                <i className="material-icons">local_phone</i>
                                <small>cell</small>
                                <span>{ cellPhone }</span>
                            </Row>
                        </Column>
                    </Row>
                </div>
            </div>
            <ConfirmatinModal
                show={showConfirm}
                message={`Are you sure you want to delete connection "${name}"?`}
                onConfirm={() => {
                    onDeleteConnection();
                    setShowConfirm(false);
                }}
                onClose={onCloseModal}
            />
        </div>
    );
}

Connection.propTypes = {
    props: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        officePhone: PropTypes.string,
        cellPhone: PropTypes.string,
        company: PropTypes.string,
    }).isRequired,
    onDeleteConnection: PropTypes.func.isRequired,
};

export default Connection;

const ITEM_HEIGHT = 48;

function ConnectionMenu({ onShowConfirmation }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="connection-menu-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'connection-menu-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem onClick={onShowConfirmation}>
                    Delete
                </MenuItem>
            </Menu>
        </div>
    );
}

ConnectionMenu.propTypes = {
    onShowConfirmation: PropTypes.func.isRequired,
};
