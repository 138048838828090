import { http } from '../../helpers';
import { updateConnectionsAction } from '../actions';

export function fetchConnections(options = {}) {
    const size = options.size || 8;
    const page = options.page || 0;
    return (dispatch, getState) => {
        const clinic = getState().connections.chosenClinic || '';
        const lab = getState().connections.chosenLab || '';
        const relativeUrl = `/case-api/connections?size=${size}&page=${page}&clinicArchived=false&labArchived=false&clinicId=${clinic}&labId=${lab}`;
        http.get(relativeUrl)
            .then(({ data }) => {
                dispatch(updateConnectionsAction(data));
            })
            .catch((error) => {
                console.warn(error);
            });
    };
}
