import React from 'react';
import PropTypes from 'prop-types';

function Table({ children }) {
    return (
        <div className="table">
            {
                children
            }
        </div>
    );
}

Table.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default Table;
