import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { Grid, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { http } from '../../helpers';
import {
    Header,
    Cases,
    SummaryWidget,
    DateRange,
    Spinner,
    Pagination,
    Input,
    ClinicPicker,
    LabPicker,
    Search,
} from '../../components';
import './styles.scss';

function CasesDashboard() {
    const dispatch = useDispatch();
    const cases = useSelector((state) => state.cases);
    const [fetching, setFetching] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedClinic, setSelectedClinic] = useState('');
    const [selectedLab, setSelectedLab] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [canReadCount, setCanReadCount] = useState(false);
    const [canReadSystemCount, setCanReadSystemCount] = useState(false);
    const [canViewClinicsFilter, setCanViewClinicsFilter] = useState(false);
    const [canViewLabsFilter, setCanViewLabsFilter] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [scanWidgetText, setScanWidgetText] = useState('');
    const [remMonthsWidgetText, setRemMonthsWidgetText] = useState('');
    const [pagination, setPagination] = useState({
        number: 0,
        totalPages: 0,
        first: false,
        last: false,
    });

    // Access
    const { scope } = localStorage;
    const scopeArray = (scope || '').split(' ');
    const hasAccess = (rule) => scopeArray.includes(rule);
    const canViewClinicsColumn = hasAccess('cases.display.clinics:read');
    const canViewLabsColumn = hasAccess('cases.display.labs:read');

    const onSuccess = ({ content, first, last, totalPages, number }) => {
        dispatch({ type: 'INITIALIZE_CASES', cases: content });
        setFetching(false);
        setPagination({ first, last, totalPages, number });
    };

    const getCases = (pageNumber = 0) => {
        setFetching(true);

        const sortField = (canViewClinicsColumn && !canViewLabsColumn)
            ? 'receivedOn'
            : 'createdOn';

        let relativeUrl = `/case-api/cases?size=10&page=${pageNumber}&sort=${sortField},DESC`;
        if (search && search.length > 0) {
            relativeUrl += `&search=${search}`;
        }
        if (selectedClinic) {
            relativeUrl += `&clinicId=${selectedClinic}`;
        }
        if (selectedLab) {
            relativeUrl += `&labId=${selectedLab}`;
        }
        if (selectedStatus) {
            relativeUrl += `&status=${selectedStatus}`;
        }
        if (startDate && endDate) {
            relativeUrl += `&${sortField}Start=${startDate}&${sortField}End=${endDate}`;
        }
        http
            .get(relativeUrl)
            .then(({ data }) => onSuccess(data))
            .catch((error) => console.warn(error));
    };

    const getWidgetData = () => {
        let countUrl = '/case-api/cases/count';
        let progressUrl = '/case-api/scanners/progress';

        const getBillingData = () => {
            http
                .get(countUrl)
                .then(({ data: { count } }) => {
                    setScanWidgetText(`${count} scans this month`);
                })
                .catch((error) => console.warn(error));
        };

        const getRemainingMonths = () => {
            http
                .get(progressUrl)
                .then(({ data: { remaining } }) => {
                    setRemMonthsWidgetText(`${remaining} months for new scanner`);
                })
                .catch((error) => console.warn(error));
        };

        if (canReadSystemCount) {
            if (selectedClinic) {
                countUrl += `?clinicId=${selectedClinic}`;
                progressUrl += `?clinicId=${selectedClinic}`;
                getBillingData();
                getRemainingMonths();
            } else {
                setScanWidgetText(null);
                setRemMonthsWidgetText(null);
            }
        } else if (canReadCount) {
            getBillingData();
            getRemainingMonths();
        }
    };

    const onChangePage = (pageNumber) => getCases(pageNumber);

    const onUpdateSearch = debounce((value) => {
        setSearch(value);
    }, 700);

    const onUpdateClinic = (clinic) => {
        setSelectedClinic(clinic);
        getWidgetData();
    };

    const onUpdateLab = (lab) => {
        setSelectedLab(lab);
    };

    const onUpdateStatus = (chosenStatus) => {
        setSelectedStatus(chosenStatus);
    };

    const onUpdateDateRange = (sDate, eDate) => {
        // The dates come from the date picker are set to
        // noon of the day selected in the user's current timezone.
        // Instead, we want midnight of the day the user chose
        // in their timezone. To correct, we'll subtract 12 hours.
        setStartDate(moment(sDate).subtract(12, 'hours').toISOString());
        setEndDate(moment(eDate).subtract(12, 'hours').toISOString());
    };

    useEffect(() => {
        setCanReadCount(hasAccess('cases.count:read'));
        setCanReadSystemCount(hasAccess('cases.count.system:read'));
        setCanViewClinicsFilter(hasAccess('cases.display.clinics:read'));
        setCanViewLabsFilter(hasAccess('cases.display.labs:read'));
        getWidgetData();
    }, []);

    useEffect(() => {
        getCases();
    }, [search, selectedClinic, selectedLab, selectedStatus, startDate, endDate]);

    return (
        <>
            <Header label="Cases Dashboard" />
            <main className="dashboard-container">
                {scanWidgetText && remMonthsWidgetText && (
                    <Grid container spacing={2} marginBottom={2}>
                        <Grid item sm={6} xs={12}>
                            <SummaryWidget text={scanWidgetText} />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <SummaryWidget text={remMonthsWidgetText} />
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={2} marginBottom={2}>
                    <Grid item md={4} xs={12}>
                        <Search
                            onChange={onUpdateSearch}
                            label="Patient name, doctor name, or order #"
                        />
                    </Grid>
                    {canViewClinicsFilter && (
                        <Grid
                            item
                            md={(canViewClinicsFilter && canViewLabsFilter) ? 2 : 3}
                            xs={(canViewClinicsFilter && canViewLabsFilter) ? 12 : 6}
                        >
                            <ClinicPicker
                                selectedClinic={selectedClinic}
                                onSelectClinic={onUpdateClinic}
                            />
                        </Grid>
                    )}
                    {canViewLabsFilter && (
                        <Grid
                            item
                            md={(canViewClinicsFilter && canViewLabsFilter) ? 2 : 3}
                            xs={(canViewClinicsFilter && canViewLabsFilter) ? 12 : 6}
                        >
                            <LabPicker
                                selectedLab={selectedLab}
                                onSelectLab={onUpdateLab}
                                includeNone
                            />
                        </Grid>
                    )}
                    <Grid
                        item
                        md={2}
                        xs={(canViewClinicsFilter && canViewLabsFilter) ? 12 : 6}
                    >
                        <Autocomplete
                            className="form-field"
                            options={['Submitted', 'Accepted']}
                            renderInput={(props) => <Input {...props} label="Status" />}
                            value={selectedStatus}
                            onChange={(_, newValue) => {
                                onUpdateStatus(newValue);
                            }}
                        />
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <DateRange
                            onHandleDateRangeFiltering={onUpdateDateRange}
                        />
                    </Grid>
                </Grid>
                {fetching && <Spinner type="page" />}
                {!cases.length && !fetching ? (
                    <p className="text-red text-align-center">No cases found</p>
                ) : (
                    <Cases cases={cases} />
                )}
                <Pagination onChangePage={onChangePage} pagination={pagination} />
            </main>
        </>
    );
}

export default CasesDashboard;
