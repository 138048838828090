import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function TableRow({ children }) {
    return (
        <div className="table-row">
            {
                children
            }
        </div>
    );
}

TableRow.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default TableRow;
