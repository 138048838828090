import React, { Component } from 'react';
import {
    Header,
    Tabs,
    Tab,
    TabContent,
} from '../../components';
import ProfileTab from './ProfileTab';
import InvoicesTab from './InvoicesTab';
import PaymentTab from './PaymentTab';
import './styles.scss';

class Profile extends Component {
    constructor() {
        super();
        this.state = {
            profileTab: true,
            invoicesTab: false,
            paymentTab: false,
        };
    }

    tabSelect = (tab) => {
        this.resetTabState();
        this.setState({ [tab]: true });
    };

    resetTabState = () => {
        this.setState({ profileTab: false, invoicesTab: false, paymentTab: false });
    };

    render() {
        const { profileTab, invoicesTab, paymentTab } = this.state;
        return (
            <>
                <Header label="Profile" />
                <div className="profile-container">
                    <Tabs>
                        <Tab label="Profile" active={profileTab} onTabSelectHandler={() => this.tabSelect('profileTab')}>
                            <span>Profile Content</span>
                        </Tab>
                        <Tab label="Invoices" active={invoicesTab} onTabSelectHandler={() => this.tabSelect('invoicesTab')}>
                            <span>Invoices Content</span>
                        </Tab>
                        <Tab label="Payments" active={paymentTab} onTabSelectHandler={() => this.tabSelect('paymentTab')}>
                            <span>Payment Content</span>
                        </Tab>
                        <TabContent>
                            {
                                profileTab && <ProfileTab />
                            }
                            {
                                invoicesTab && <InvoicesTab />
                            }
                            {
                                paymentTab && <PaymentTab />
                            }
                        </TabContent>
                    </Tabs>
                </div>
            </>

        );
    }
}

export default Profile;
