/* eslint-disable react/jsx-indent-props */

import PropTypes from 'prop-types';
import { TableRow, TableData, TableDataWrapper, TableRowDrop } from '../..';
import LabDetail from '../LabDetail';

function LabRow({ data, onClickRow, open }) {
    return (
        <TableRow>
            <TableDataWrapper onClick={onClickRow}>
                <TableData data={data.name} span="2" />
                <TableData data={data.company} span="3" />
                <TableData data={data.email} span="2" />
                <TableData data={data.officePhone} dataType="phone" span="2" />
                <TableData data={data.cellPhone} dataType="phone" span="2" />
            </TableDataWrapper>
            <TableRowDrop open={open} openHeight="150px">
                <LabDetail lab={data} />
            </TableRowDrop>
        </TableRow>
    );
}

LabRow.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        company: PropTypes.string,
        email: PropTypes.string,
        officePhone: PropTypes.string,
        cellPhone: PropTypes.string,
    }).isRequired,
    onClickRow: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default LabRow;
