import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function Spinner({ type }) {
    return <div className={`spinner ${type}`} />;
}

Spinner.propTypes = {
    type: PropTypes.string.isRequired,
};

export default Spinner;
