import React, { Component } from 'react';
import { http } from '../../../helpers';
import {
    Spinner,
    Table,
    TableHead,
    TableBody,
    TableHeading,
    TableRow,
    TableData,
    TableDataWrapper,
} from '../../../components';
import './style.scss';

class InvoicesTab extends Component {
    constructor() {
        super();
        this.state = {
            fetching: false,
            invoices: [],
        };
    }

    componentDidMount() {
        this.setState({ fetching: true });
        http.get('/case-api/transactions/current')
            .then(({ data }) => this.onSuccess(data))
            .catch((error) => console.warn(error));
    }

    onSuccess = (data) => {
        this.setState({ fetching: false, invoices: [] });
        console.log('data', data);
    };

    render() {
        const { fetching, invoices } = this.state;
        const table = (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeading heading="Id" span="1" />
                        <TableHeading heading="Amount" span="2" />
                        <TableHeading heading="Date" span="3" />
                        <TableHeading heading="Invoice #" span="3" />
                        <TableHeading heading="Status" span="3" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        invoices.map((invoice) => (
                            <TableRow>
                                <TableDataWrapper>
                                    <TableData data={invoice.id} span="1" />
                                    <TableData data={invoice.amount} span="2" />
                                    <TableData data={invoice.date} span="3" />
                                    <TableData data={invoice.invoiceNumber} span="3" />
                                    <TableData data={invoice.status} span="3" />
                                </TableDataWrapper>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        );

        const spinner = (
            <Spinner type="page" />
        );

        return (
            <div className="invoices-tab">
                {
                    fetching ? spinner : table
                }
            </div>
        );
    }
}

export default InvoicesTab;
