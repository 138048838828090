import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { Input } from '..';

function PhoneInput({ onChange, label, value, errorText }) {
    return (
        <InputMask
            mask="(999) 999 9999"
            maskChar=" "
            value={value}
            onChange={onChange}
        >
            {() => (
                <Input
                    className="form-field"
                    label={label}
                    variant="outlined"
                    error={!!errorText}
                    helperText={errorText}
                />
            )}
        </InputMask>
    );
}

PhoneInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    errorText: PropTypes.string,
};

PhoneInput.defaultProps = {
    value: '',
    errorText: '',
};

export default PhoneInput;
