/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import ClearAlignerThumbnails from './ClearAlignerThumbnails';
import NonClearAlignerPdf from './NonClearAlignerPdf';
import Renders from './Renders';
import Download from './Download';
import {
    LabPicker,
    Row,
    Column,
} from '../..';
import './styles.scss';
import { updateCase } from '../../../redux/thunks';

function CaseDetail({
    id,
    upperStlImageUrl,
    lowerStlImageUrl,
    fullStlImageUrl,
    upperPlyImageUrl,
    lowerPlyImageUrl,
    fullPlyImageUrl,
    prescriptionUrl,
    scanZipUrl,
    thumbnailUrl,
    template,
    instructions,
    labId,
    status,
    scans,
}) {
    const dispatch = useDispatch();
    const [showFullID, setShowFullID] = useState(false);
    const [showAddLabModal, setShowAddLabModal] = useState(false);
    const [chosenLab, setChosenLab] = useState({});
    const shortenId = (x) => x.substring(0, 4);
    const id4 = shortenId(id);
    const onSeeFullID = () => setShowFullID(!showFullID);
    const getIDString = () => (showFullID ? id : id4);
    const onCopy = () => navigator.clipboard.writeText(getIDString());

    const { scope } = localStorage;
    const scopeArray = (scope || '').split(' ');
    const hasAccess = (rule) => scopeArray.includes(rule);
    const canDownloadPrescription = hasAccess('cases.prescriptions:download');
    const canUpdateCaseStatus = hasAccess('cases.status:write');
    const canDownloadSTL = hasAccess('cases.scans:download');

    const onCloseAddLabModal = () => setShowAddLabModal(false);
    const onOpenAddLabModal = () => setShowAddLabModal(true);
    const onMoveCaseToLab = () => {
        dispatch(updateCase({ id, labId: chosenLab?.id || 'none', labName: chosenLab?.name }));
        onCloseAddLabModal();
    };

    return (
        <>
            <div className="order-id-container" styles="no-gutters">
                <i className="material-icons" role="presentation" onClick={onSeeFullID}>visibility</i>
                <div className="id" role="presentation" onClick={onCopy}><b>ID:</b> { getIDString() } </div>
            </div>
            <Row styles="no-gutters">
                {
                    template === 'Clear Aligner'
                        ? (
                            <>
                                <Column span="2" styles="overflow-x">
                                    <div className="details">
                                        <span>Template</span>
                                        <p>{template || 'None'}</p>
                                        <span>Instructions</span>
                                        <p className="case-instructions-p">{instructions || 'None'}</p>
                                    </div>
                                </Column>
                                <Column span="5" styles="no-padding overflow-x">
                                    <ClearAlignerThumbnails url={thumbnailUrl} />
                                </Column>
                            </>
                        )
                        : (
                            <Column span="8" styles="no-padding overflow-x">
                                <NonClearAlignerPdf prescriptionUrl={prescriptionUrl} />
                            </Column>
                        )
                }
                <Column span="2" styles="no-padding scroll-y">
                    <Renders
                        id={id}
                        upperStlImageUrl={upperStlImageUrl}
                        lowerStlImageUrl={lowerStlImageUrl}
                        fullStlImageUrl={fullStlImageUrl}
                        upperPlyImageUrl={upperPlyImageUrl}
                        lowerPlyImageUrl={lowerPlyImageUrl}
                        fullPlyImageUrl={fullPlyImageUrl}
                        scanZipUrl={scanZipUrl}
                        thumbnailUrl={thumbnailUrl}
                        scans={scans}
                    />
                </Column>
                <Column span="2" styles="no-padding">
                    <Download
                        id={id}
                        id4={id4}
                        prescriptionUrl={prescriptionUrl}
                        scanZipUrl={scanZipUrl}
                        canDownloadPrescription={canDownloadPrescription}
                        canUpdateCaseStatus={canUpdateCaseStatus}
                        canDownloadSTL={canDownloadSTL}
                    />
                    { status === 'Submitted' && hasAccess('cases:write') && (
                        <div className="add-lab-row">
                            <Row>
                                <Column span="12">
                                    <button type="button" className="btn btn-icon full-width" onClick={onOpenAddLabModal}>
                                        <i className="material-icons">business</i>
                                        {labId ? 'Change Lab' : 'Add Lab'}
                                    </button>
                                </Column>
                            </Row>
                        </div>
                    )}
                </Column>
            </Row>
            <Dialog onClose={onCloseAddLabModal} open={showAddLabModal}>
                <div className="modal-lab-picker">
                    <LabPicker
                        selectedLab={chosenLab?.id || labId}
                        onSelectLab={setChosenLab}
                        includeNone
                        returnObject
                    />
                    <div className="dialog-button-container">
                        <button type="button" className="btn btn-icon full-width" onClick={onCloseAddLabModal}>
                            <i className="material-icons">cancel</i>
                            Cancel
                        </button>
                        <button type="button" className="btn btn-icon full-width" onClick={onMoveCaseToLab}>
                            <i className="material-icons">save</i>
                            {labId && chosenLab?.id === 'none' ? 'Unassign' : 'Assign'}
                        </button>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

CaseDetail.propTypes = {
    id: PropTypes.string.isRequired,
    upperStlImageUrl: PropTypes.string,
    lowerStlImageUrl: PropTypes.string,
    fullStlImageUrl: PropTypes.string,
    upperPlyImageUrl: PropTypes.string,
    lowerPlyImageUrl: PropTypes.string,
    fullPlyImageUrl: PropTypes.string,
    prescriptionUrl: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    scanZipUrl: PropTypes.string,
    template: PropTypes.string,
    instructions: PropTypes.string,
    labId: PropTypes.string,
    status: PropTypes.string,
    scans: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        dentalCaseId: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
};

CaseDetail.defaultProps = {
    upperStlImageUrl: '',
    lowerStlImageUrl: '',
    fullStlImageUrl: '',
    upperPlyImageUrl: '',
    lowerPlyImageUrl: '',
    fullPlyImageUrl: '',
    prescriptionUrl: '',
    thumbnailUrl: '',
    scanZipUrl: '',
    template: '',
    instructions: '',
    labId: null,
    status: '',
};

export default CaseDetail;
