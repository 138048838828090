import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Sidebar from '../Sidebar';
import Logout from './Logout';
import Branding from './Branding';

function Header({ label, showMenu }) {
    const dispatch = useDispatch();
    const drawerOpened = useSelector((state) => state.menu.opened);
    const handleDrawerToggle = () => dispatch({ type: 'SET_MENU', opened: !drawerOpened });
    const closeDrawer = () => dispatch({ type: 'SET_MENU', opened: false });
    const myTheme = useTheme();
    const matchUpMd = useMediaQuery(myTheme.breakpoints.up('md'));

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (matchUpMd && drawerOpened && ref.current && !ref.current.contains(event.target)) {
                    closeDrawer();
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref, drawerOpened]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const BoxStyled = styled(Box)(({ theme }) => ({
        padding: '16px 25px',
        marginBottom: '5px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '15px',
            padding: '0px',
            paddingTop: '10px',
        },
    }));

    return (
        <BoxStyled sx={{ flexGrow: 1 }} ref={wrapperRef}>
            <Sidebar drawerOpen={drawerOpened} drawerClose={closeDrawer} />
            <AppBar position="static" sx={{ backgroundColor: 'white', color: 'black', boxShadow: 0 }}>
                <Toolbar>
                    { showMenu && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 1, mt: 1 }}
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Branding label={label} />
                    { showMenu && <Logout /> }
                </Toolbar>
            </AppBar>
        </BoxStyled>
    );
}

Header.propTypes = {
    label: PropTypes.string.isRequired,
    showMenu: PropTypes.bool,
};

Header.defaultProps = {
    showMenu: true,
};

export default Header;
