import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Document,
    Page,
    pdfjs,
} from 'react-pdf';
// eslint-disable-next-line import/no-extraneous-dependencies
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Spinner } from '../../..';
import { http } from '../../../../helpers';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function NonClearAlignerPdf({
    prescriptionUrl,
}) {
    const [fetching, setFetching] = useState(true);
    const [failed, setFailed] = useState(false);
    const [pdfData, setPdfData] = useState(null);
    const [numberOfPages, setNumberOfPages] = useState();
    const onPdfLoadSuccess = ({ numPages }) => { setNumberOfPages(numPages); };

    useEffect(() => {
        if (pdfData) return;
        console.log(prescriptionUrl);

        http.getBlob(prescriptionUrl, 'application/pdf')
            .then(async (response) => {
                const blob = new Blob([response.data], {
                    type: 'application/pdf',
                });
                setPdfData(blob);
                setFetching(false);
            })
            .catch((error) => {
                console.log(error);
                setFetching(false);
                setFailed(true);
            });
    });

    return (
        <>
            {
                fetching && <Spinner type="parent-center" />
            }
            {
                !fetching && !failed && (
                    <Document
                        file={pdfData}
                        loading={() => ''}
                        onLoadSuccess={onPdfLoadSuccess}
                    >
                        {
                            Array.from(
                                new Array(numberOfPages),
                                (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        loading={() => ''}
                                        scale={1.5}
                                        renderAnnotationLayer={false}
                                        renderTextLayer={false}
                                    />
                                ),
                            )
                        }
                    </Document>
                )
            }
            {
                !fetching && failed && (
                    <div>Request failed with an error.</div>
                )
            }
        </>
    );
}

NonClearAlignerPdf.propTypes = {
    prescriptionUrl: PropTypes.string.isRequired,
};

NonClearAlignerPdf.defaultProps = {};

export default NonClearAlignerPdf;
