import React, { Component } from 'react';
import { http } from '../../../helpers';
import { Spinner, Row, Column } from '../../../components';
import './style.scss';

class ProfileTab extends Component {
    constructor() {
        super();
        this.state = {
            clinicId: null,
            name: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zip: null,
            email: null,
            officePhone: null,
            cellPhone: null,
            fetching: false,
            submitting: false,
            success: false,
            error: false,
            errorMessage: '',
        };
    }

    componentDidMount() {
        this.setState({ fetching: true });
        http.get('/case-api/clinics/current')
            .then(({ data }) => this.onSuccess(data))
            .catch((error) => console.warn(error));
    }

    onSuccess = ({ name, address1, address2, city, state, zip, email, officePhone, cellPhone }) => {
        this.setState({ name, address1, address2, city, state, zip, email, officePhone, cellPhone, fetching: false });
    };

    changeHandler = ({ name, value }) => {
        this.setState({ [name]: value });
    };

    submitHandler = (event) => {
        event.preventDefault();
        const { clinicId, name, address1, address2, city, state, zip, email, officePhone, cellPhone } = this.state;
        this.setState({ error: '', submitting: true });
        http.put('/case-api/clinics/current', { clinicId, name, address1, address2, city, state, zip, email, officePhone, cellPhone })
            .then((response) => this.onSubmitSuccess(response))
            .catch((error) => this.onSubmitError(error));
    };

    onSubmitSuccess = (response) => {
        console.log(response);
        this.setState({ submitting: false, success: true, error: false, errorMessage: '' });
    };

    onSubmitError = (error) => {
        console.warn(error);
        this.setState({ success: false, error: true, errorMessage: error.message });
    };

    formIsValid = () => {
        const { name, email, officePhone, address1, city, state, zip } = this.state;
        return name && email && officePhone && address1 && city && state && zip;
    };

    render() {
        const { name, address1, address2, city, state, zip, email, officePhone, cellPhone, fetching, submitting, success, error, errorMessage } = this.state;
        const form = (
            <form onSubmit={this.submitHandler}>
                <div className="input-group-column">
                    <label htmlFor="name">
                        *Name
                        <input name="name" placeholder="Name" defaultValue={name} onChange={(e) => this.changeHandler(e.target)} />
                    </label>
                </div>
                <label htmlFor="email">
                    *Email
                    <input type="email" name="email" placeholder="example@gmail.com" defaultValue={email} disabled />
                </label>
                <div className="input-group">
                    <div className="input-group-column span-6">
                        <label htmlFor="officephone">
                            *Office Phone
                            <input name="officephone" placeholder="Office Phone" defaultValue={officePhone} onChange={(e) => this.changeHandler(e.target)} />
                        </label>
                    </div>
                    <div className="input-group-column span-6">
                        <label htmlFor="personalphone">
                            Cell (optional)
                            <input name="personalphone" placeholder="Personal Phone" defaultValue={cellPhone} onChange={(e) => this.changeHandler(e.target)} />
                        </label>
                    </div>
                </div>
                <label htmlFor="address1">
                    *Address
                    <input name="address1" placeholder="*Address" defaultValue={address1} onChange={(e) => this.changeHandler(e.target)} />
                </label>
                <label htmlFor="address2">
                    Address 2 (optional)
                    <input name="address2" placeholder="Address 2" defaultValue={address2} onChange={(e) => this.changeHandler(e.target)} />
                </label>
                <div className="input-group">
                    <div className="input-group-column span-5">
                        <label htmlFor="city">
                            *City
                            <input name="city" placeholder="City" defaultValue={city} onChange={(e) => this.changeHandler(e.target)} />
                        </label>
                    </div>
                    <div className="input-group-column span-2">
                        <label htmlFor="state">
                            *State
                            <input name="state" placeholder="State" defaultValue={state} onChange={(e) => this.changeHandler(e.target)} />
                        </label>
                    </div>
                    <div className="input-group-column span-5">
                        <label htmlFor="zip">
                            *Zip
                            <input name="zip" placeholder="Zip" defaultValue={zip} onChange={(e) => this.changeHandler(e.target)} />
                        </label>
                    </div>
                </div>
                <Row>
                    <Column span="11" styles="text-align-right" divider={false}>
                        {
                            success && <span className="text-success text-bold response-message">Successfully saved</span>
                        }
                        {
                            error && <span className="text-red text-bold response-message">{ errorMessage }</span>
                        }
                    </Column>
                    <Column span="1" divider={false}>
                        <button className="btn btn-dark" type="submit" disabled={!this.formIsValid() || submitting}>
                            Submit
                            {
                                submitting && <Spinner type="inline" />
                            }
                        </button>
                    </Column>
                </Row>
            </form>
        );
        const spinner = (
            <Spinner type="page" />
        );
        return (
            <div className="profile-tab">
                <p className="text-center text-red text-bold">
                    {
                        error
                    }
                </p>
                {
                    fetching ? spinner : form
                }
            </div>
        );
    }
}

export default ProfileTab;
