import React from 'react';
import PropTypes from 'prop-types';

function ModalFooter({ children }) {
    return (
        <div className="modal-footer">
            {
                children
            }
        </div>
    );
}

ModalFooter.propTypes = {
    children: PropTypes.element,
};

ModalFooter.defaultProps = {
    children: null,
};

export default ModalFooter;
