import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { clearConnectionsAction } from '../../../redux/reducers/connections.reducer';
import { clearGlobalStateAction } from '../../../redux/reducers/global.reducer';

function Logout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onLogout = () => {
        localStorage.clear();
        dispatch(clearConnectionsAction());
        dispatch(clearGlobalStateAction());
        navigate('/login');
    };

    return (
        <IconButton onClick={onLogout}>
            <LogoutIcon />
        </IconButton>
    );
}

export default Logout;
