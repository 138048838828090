// import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import PropTypes from 'prop-types';
import { Typography, Box, Grid, Card } from '@mui/material';
import { styled } from '@mui/material/styles';

const CardWrapper = styled(Card)(() => ({
    // backgroundColor: '#f7f8fa',
    // border: '0.1px solid grey',
    borderRadius: '10px',
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 150,
        height: 150,
        background: 'linear-gradient(10.04deg, #e4e7ed -50.94%, rgba(144, 202, 249, 0) 55.49%)',
        // background: 'linear-gradient(95deg, rgba(231,231,231,1) 0%, rgba(255,255,255,0.9528405112044818) 19%)',
        // backgroundColor: 'grey',
        opacity: 0.7,
        borderRadius: '50%',
        top: -120,
        left: -40,
        // bottom: -75,
        // left: -125,
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 150,
        height: 150,
        background: 'linear-gradient(40deg, #e4e7ed -14.02%, rgba(144, 202, 249, 0) 60.50%)',
        // backgroundColor: 'grey',
        // opacity: 0.5,
        borderRadius: '50%',
        bottom: 0,
        left: -95,
    },
}));

// const DetailsButton = styled(Button)(() => ({
//     textTransform: 'none',
// }));

function SummaryWidget({ text }) {
    // const link = clinicId
    //     ? `/dashboard?clinicId=${clinicId}`
    //     : '/dashboard';
    return (
        <CardWrapper>
            <Box sx={{ p: 1.75 }}>
                {/* <Grid container> */}
                <Grid item lg={12} display="flex" justifyContent="center">
                    <Typography
                        sx={{ fontSize: '1.2rem', color: 'black', opacity: 0.75 }}
                    >
                        {text}
                    </Typography>
                </Grid>
                {/* <Grid item lg={2} display="flex" justifyContent="center">
                        <DetailsButton href={link} size="small" disableElevation>
                            Details
                            <ChevronRightOutlinedIcon />
                        </DetailsButton>
                    </Grid> */}
                {/* </Grid> */}
            </Box>
        </CardWrapper>
    );
}

SummaryWidget.propTypes = {
    text: PropTypes.string.isRequired,
};

export default SummaryWidget;
