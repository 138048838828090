import { useEffect, useState } from 'react';
import './styles.scss';
import { useSearchParams, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/ori_logo.png';
import config from '../../config';

function PasswordReset() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [invitationId, setInvitationId] = useState(null);
    const [token, setToken] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        const invitationIdParam = searchParams.get('id');
        const tokenParam = searchParams.get('token');

        if (!tokenParam || !invitationIdParam) navigate('/login');

        setInvitationId(invitationIdParam);
        setToken(tokenParam);
    }, []);

    const onResetPassword = (evt) => {
        evt.preventDefault();
        if (confirmPassword !== password) {
            return setError('Passwords don\'t match');
        }
        const body = {
            id: invitationId,
            password,
            token,
        };
        return fetch(`${config.apiUrl}/case-api/profile/reset-password`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' },
        })
            .then(async (res) => {
                // TODO: find out why catch not hitting on errors
                if (res.status < 200 || res.status > 300) {
                    const result = await res.json();
                    setError(result.error || 'Failed to reset password');
                } else {
                    setError('');
                    setShowConfirmation(true);
                }
            })
            .catch((response) => {
                setError('Failed to reset password');
                console.log('Request failed', response);
            });
    };
    const goToLogin = () => {
        navigate('/login');
    };
    const onSetPassword = (evt) => {
        setPassword(evt.target.value);
    };
    const onSetConfirmPassword = (evt) => {
        setConfirmPassword(evt.target.value);
    };

    if (showConfirmation) {
        return (
            <div className="flex-container">
                <div className="password-reset-container">
                    <img src={logo} alt="Ori Dental Scanner logo" />
                    <h6>ORI DENTAL SCANNER</h6>
                    <p className="text-center text-bold">
                        Password successfully changed. You can now login.
                    </p>
                    <div className="login-btn-container">
                        <button className="btn btn-dark" type="button" onClick={goToLogin}>Login</button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex-container">
            <div className="password-reset-container">
                <img src={logo} alt="Ori Dental Scanner logo" />
                <h6>ORI DENTAL SCANNER</h6>
                <p className="text-center text-red text-bold">
                    {
                        error
                    }
                </p>
                <form onSubmit={onResetPassword}>
                    <label htmlFor="password">
                        Enter a new password
                        <input
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={onSetPassword}
                        />
                    </label>
                    <label htmlFor="password-confirm">
                        Confirm new password
                        <input
                            name="password-confirm"
                            type="password"
                            placeholder="Password Confirm"
                            onChange={onSetConfirmPassword}
                        />
                    </label>
                    <button className="btn btn-dark" type="submit" onClick={onResetPassword}>Submit</button>
                    <button className="btn" type="button" onClick={goToLogin}>Cancel</button>
                </form>
            </div>
        </div>
    );
}

export default PasswordReset;
