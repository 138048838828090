import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function Tabs({ children }) {
    return (
        <div className="tabs">
            <ul>
                {
                    children
                }
            </ul>
        </div>
    );
}

Tabs.propTypes = {
    children: PropTypes.node,
};

Tabs.defaultProps = {
    children: null,
};

export default Tabs;
