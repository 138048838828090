/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */

import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Alert, Grid } from '@mui/material';
import { http, LAB, CLINIC, ADMIN } from '../../helpers';
import { Spinner, CheckBox } from '..';
// import { addConnection } from '../../redux/reducers/connections.reducer';
import './styles.scss';

const dropdownId = 'add-lab-connection-options-list';
const inputId = 'lab-connections-drop-input';
const byName = (a, b) => {
    const compare = (str1, str2) => str1.toLowerCase() < str2.toLowerCase();
    if (compare(a.name, b.name)) return -1;
    if (compare(b.name, a.name)) return 1;
    return 0;
};

function AddConnectionForm(props) {
    let searchInput = useRef();
    const [drop, setDrop] = useState(false);
    const [invite, setInvite] = useState('');
    const [searchSubmitting, setSearchSubmitting] = useState(false);
    const [inviteSubmitting, setInviteSubmitting] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showInvite, setShowInvite] = useState(false);
    const [showAdd, setShowAdd] = useState(true);
    const [visibleEntities, setVisibleEntities] = useState([]);
    const [confirmPermission, setConfirmPermission] = useState(false);
    const [fail, setFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Something went wrong');
    const [chosen, setChosen] = useState({
        id: '',
        name: '',
    });

    useEffect(() => {
        const onClickAwayFromLabsDropdown = (evt) => {
            if (![dropdownId, inputId].includes(evt.target.id)) setDrop(false);
        };
        document.querySelector('body').addEventListener('click', onClickAwayFromLabsDropdown);
        return () => {
            // cleanup
            document.querySelector('body').removeEventListener('click', onClickAwayFromLabsDropdown);
            setDrop(false);
            setSearchSubmitting(false);
            setInviteSubmitting(false);
            setChosen({
                id: '',
                name: '',
            });
            setSearchTerm('');
            setShowAdd(true);
            setShowInvite(false);
        };
    }, []);

    useEffect(() => {
        if (showInvite) setShowAdd(false);
    }, [showInvite]);

    useEffect(() => {
        setVisibleEntities(props.entities?.filter((l) => l.name.toLowerCase().includes(searchTerm.toLowerCase())));
    }, [searchTerm, props.entities]);

    const changeHandler = ({ name, value }) => {
        setChosen({ ...chosen, [name]: value });
        if (name === 'search') {
            setSearchTerm(value);
        }
    };

    const searchSelectHandler = (e) => {
        const { entities } = props;
        const selected = entities.find((l) => l.id === e.target.id);
        setSearchTerm(selected?.name);
        const { id, name, company, email, officePhone, cellPhone, imageUrl } = selected;
        searchInput.value = name;
        setDrop(!drop);
        setChosen({ ...chosen, id, name, company, email, officePhone, cellPhone, imageUrl });
        e.preventDefault();
    };

    const onInviteSubmitSuccess = () => {
        const { closeModalHandler } = props;
        setInviteSubmitting(false);
        closeModalHandler();
    };

    const submitInviteHandler = (e) => {
        setInviteSubmitting(true);
        http.post('/case-api/connections/invitations', { email: invite })
            .then((response) => onInviteSubmitSuccess(response))
            .catch(({ request }) => {
                const response = JSON.parse(request?.response);
                const error = response?.error;
                if (error) setErrorMessage(error);
                setFail(true);
                setInviteSubmitting(false);
            });
        e.preventDefault();
    };

    const submitSearchHandler = () => {
        const { role, chosenLab, chosenClinic, closeModalHandler } = props;
        setSearchSubmitting(true);
        const payload = role === ADMIN
            ? {
                labId: chosenLab || chosen.id,
                clinicId: chosenClinic || chosen.id,
            } : {
                labId: role === CLINIC ? chosen.id : '',
                clinicId: role === LAB ? chosen.id : '',
            };
        http.post('/case-api/connections/invitations', payload)
            .then(() => props.refreshLabs()) // eslint-disable-line react/destructuring-assignment
            .catch((error) => console.warn(error))
            .finally(() => closeModalHandler(false));
    };

    const dropHandler = () => {
        setDrop(!drop);
    };

    const searchIsValid = () => chosen.name;

    const inviteIsValid = () => {
        const re = /\S+@\S+\.\S+/;
        return re.test(invite) && confirmPermission;
    };
    const showInviteInput = () => setShowInvite(true);
    const onSetConfirmPermission = (evt) => setConfirmPermission(evt.target.checked);

    const { role, closeModalHandler } = props;
    return (
        <div className="add-connection">
            <div className="add-connection-header">
                <h2>Add or Invite a Connection</h2>
            </div>
            <div className="add-connection-body">

                { showAdd && (
                    <form>
                        <div className="add-connection-group">
                            <label htmlFor="search">
                                Search connections to add
                                {
                                    props.entities // eslint-disable-line react/destructuring-assignment
                                        ? (
                                            <input
                                                id={inputId}
                                                className="search"
                                                ref={(ref) => { searchInput = ref; }}
                                                name="search"
                                                placeholder="Business Name"
                                                value={searchTerm}
                                                onClick={() => dropHandler()}
                                                onChange={(e) => changeHandler(e.target)}
                                                autoComplete="off"
                                            />
                                        )
                                        : <input className="search red-disabled" name="search" placeholder="None found" disabled />
                                }
                                { !!visibleEntities.length && (
                                    <div className={`search-drop ${drop && 'drop'}`}>
                                        <ul id={dropdownId}>
                                            {
                                                visibleEntities
                                                    .sort(byName)
                                                    .map((l) => <li key={l.id} id={l.id} onClick={searchSelectHandler} role="presentation">{l.name}</li>) // eslint-disable-line react/destructuring-assignment
                                            }
                                        </ul>
                                    </div>
                                ) }
                            </label>
                            <div className="add-connection-button-group">
                                <button
                                    className="btn btn-dark"
                                    type="button"
                                    onClick={(e) => submitSearchHandler(e)}
                                    disabled={searchSubmitting || !searchIsValid()}
                                >
                                    Add
                                    {
                                        searchSubmitting && <Spinner type="inline" />
                                    }
                                </button>
                                <button className="btn" type="button" onClick={() => closeModalHandler()} disabled={searchSubmitting}>Cancel</button>
                            </div>
                            { searchTerm && !visibleEntities.length && role !== ADMIN
                                  && (
                                      <span className="show-invite-button-container">
                                          <span>{'Can\'t find what you\'re looking for?'}</span>
                                          <button className="show-invite-button" type="button" onClick={showInviteInput}>{`Invite a ${role === LAB ? 'Clinic' : 'Lab'}`}</button>
                                      </span>
                                  ) }
                        </div>
                    </form>
                )}
                { showInvite && (
                    <form onSubmit={submitInviteHandler}>
                        <div className="add-connection-group">
                            <Grid
                                item
                                style={{ height: '60px' }}
                            >
                                {fail && (
                                    <Alert severity="error">
                                        { errorMessage }
                                    </Alert>
                                )}
                            </Grid>
                            <label htmlFor="username">
                                Invite connection
                                <input
                                    type="email"
                                    name="invite"
                                    placeholder="Enter email address"
                                    onChange={(e) => setInvite(e.target.value)}
                                    required
                                />
                            </label>
                            <CheckBox
                                label="I have obtained permission from the owner of the above email to disclose said email to ORI, LLC, for the purpose of inviting them to the ORI Platform."
                                checked={confirmPermission}
                                onChange={onSetConfirmPermission}
                            />
                            <div className="add-connection-button-group">
                                <button
                                    className="btn btn-dark"
                                    type="submit"
                                    disabled={inviteSubmitting || !inviteIsValid()}
                                >
                                    Invite
                                    {
                                        inviteSubmitting && <Spinner type="inline" />
                                    }
                                </button>
                                <button className="btn" type="button" onClick={() => closeModalHandler()} disabled={inviteSubmitting}>Cancel</button>
                            </div>
                        </div>
                    </form>
                ) }

            </div>
        </div>
    );
}

AddConnectionForm.propTypes = {
    closeModalHandler: PropTypes.func.isRequired,
    refreshLabs: PropTypes.func.isRequired,
    entities: PropTypes.arrayOf(PropTypes.shape),
    role: PropTypes.string.isRequired,
    chosenLab: PropTypes.string,
    chosenClinic: PropTypes.string,
};

AddConnectionForm.defaultProps = {
    entities: [],
    chosenLab: '',
    chosenClinic: '',
};

export default AddConnectionForm;
