import { styled } from '@mui/material/styles';
import { Select } from '@mui/material';

const SelectStyled = styled(Select)({
    width: '100%',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
    },
});

export default SelectStyled;
