import { http } from '../../helpers';

export function fetchUsers(page = 0) {
    return (dispatch, getState) => {
        const { filters } = getState().users;
        const filterString = Object.keys(filters).map((key) => `${key}=${filters[key]}`).join('&');

        const relativeUrl = `/case-api/users?size=10&page=${page}&${filterString}`;
        http.get(relativeUrl)
            .then(({ data }) => {
                dispatch({
                    type: 'users/UPDATE_STATE',
                    users: data.content,
                    pagination: {
                        number: data.number,
                        totalPages: data.totalPages,
                        first: data.first,
                        last: data.last,
                    },
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
