/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import getRefreshedToken from './getRefreshedToken';
import updateLocalStorage from './updateLocalStorage';
import { REFRESH_BUFFER_SECONDS } from './constants';
import config from '../config';

// set environment domain based off .env file
axios.defaults.baseURL = config.apiUrl;

// check and see if a token exists and assign
axios.interceptors.request.use((reqConfig) => {
    const jwt = localStorage.getItem('token');
    if (jwt !== null) {
        reqConfig.headers.Authorization = `Bearer ${jwt}`;
    }
    return reqConfig;
}, (err) => Promise.reject(err));

const checkToken = async () => {
    const token = localStorage.getItem('token');
    if (!token) window.location.href = '/login';
    const decodedToken = jwt_decode(token);
    const secondsSinceEpoch = new Date().getTime() / 1000;
    const threshhold = secondsSinceEpoch + REFRESH_BUFFER_SECONDS;

    if (decodedToken.exp < threshhold) {
        return getRefreshedToken().then(updateLocalStorage);
    }

    return 1;
};

// setup http data object
const http = {
    post: (...args) => checkToken().then(() => axios.post(...args)),
    postAnonymous: (...args) => axios.post(...args),
    get: (...args) => checkToken().then(() => axios.get(...args)),
    put: (...args) => checkToken().then(() => axios.put(...args)),
    delete: (...args) => checkToken().then(() => axios.delete(...args)),
    getBlob: (route, accept) => axios.get(route, { responseType: 'arraybuffer', headers: { Accept: accept } }),
};

export default http;
