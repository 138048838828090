import { useState } from 'react';
import PropTypes from 'prop-types';
import ClinicRow from './ClinicRow';
import {
    Table,
    TableHead,
    TableBody,
    TableHeading,
    TableRow,
} from '..';

function ClinicList({ clinics }) {
    const [openRow, setOpenRow] = useState('');

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeading heading="Name" span="3" />
                    <TableHeading heading="City" span="2" />
                    <TableHeading heading="State" span="1" />
                    <TableHeading heading="Email" span="2" />
                    <TableHeading heading="Office Phone" span="2" />
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    clinics && clinics.map((c) => (
                        <ClinicRow
                            data={c}
                            onClickRow={() => setOpenRow(openRow === c.id ? '' : c.id)}
                            open={openRow === c.id}
                            key={c.id}
                        />
                    ))
                }
            </TableBody>
        </Table>
    );
}

ClinicList.propTypes = {
    clinics: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        email: PropTypes.string,
        officePhone: PropTypes.string,
        cellPhone: PropTypes.string,
        archived: PropTypes.bool,
    })),
};

ClinicList.defaultProps = {
    clinics: [],
};

export default ClinicList;
