import * as THREE from 'three';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader';

class PlyViewer extends Component {
    componentDidMount() {
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0xffffff);

        // Add Renderer
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setSize(width, height);
        this.mount.appendChild(this.renderer.domElement);

        // add Camera
        this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        this.camera.position.z = 20;
        this.camera.position.y = 5;

        // Camera Controls
        const controls = new OrbitControls(this.camera, this.renderer.domElement);
        controls.enableDamping = true;

        // LIGHTS
        const lights = [];
        lights[0] = new THREE.PointLight(0xffffff, 1, 0);
        lights[1] = new THREE.PointLight(0xffffff, 1, 0);
        lights[2] = new THREE.PointLight(0xffffff, 1, 0);
        lights[0].position.set(0, 200, 0);
        lights[1].position.set(100, 200, 100);
        lights[2].position.set(-100, -200, -100);
        this.scene.add(lights[0]);
        this.scene.add(lights[1]);
        this.scene.add(lights[2]);

        // Simple Box with WireFrame
        this.addModels();

        this.renderScene();
        // start animation
        this.start();
    }

    componentWillUnmount() {
        this.stop();
        this.mount.removeChild(this.renderer.domElement);
    }

    start = () => {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate);
        }
    };

    stop = () => {
        cancelAnimationFrame(this.frameId);
    };

    animate = () => {
        this.renderScene();
        this.frameId = window.requestAnimationFrame(this.animate);
    };

    renderScene = () => {
        if (this.renderer) this.renderer.render(this.scene, this.camera);
    };

    addModels() {
        const { url } = this.props;
        if (!url) return;

        const material = new THREE.MeshPhongMaterial({ isMaterial: true, vertexColors: true, side: THREE.DoubleSide });

        const loader = new PLYLoader();
        loader.load(
            url,
            (geometry) => {
                geometry.computeVertexNormals();
                const mesh = new THREE.Mesh(geometry, material);
                mesh.rotateX(-Math.PI / 2);
                this.scene.add(mesh);
            },
            (error) => {
                console.log(error);
            },
        );
    }

    render() {
        return (
            <div
                style={{ width: '100%', height: '100%' }}
                ref={(mount) => {
                    this.mount = mount;
                }}
            />
        );
    }
}

PlyViewer.propTypes = {
    url: PropTypes.string.isRequired,
};

export default PlyViewer;
