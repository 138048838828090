import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import LanIcon from '@mui/icons-material/Lan';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { useEffect, useState } from 'react';
import NavGroup from './NavGroup';

function MenuList() {
    const { scope } = localStorage;
    const scopeArray = (scope || '').split(' ');
    const hasAccess = (rule) => scopeArray.includes(rule);
    const auth = {
        profile: hasAccess('profile'),
        clinics: hasAccess('clinics:write'),
        labs: hasAccess('labs:write'),
        users: hasAccess('users:write'),
    };

    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        const dashboardItems = {
            id: 'dashboard',
            title: 'Dashboards',
            type: 'group',
            children: [
                {
                    id: '2',
                    title: 'Cases',
                    type: 'item',
                    url: '/cases',
                    icon: TableRowsIcon,
                },
                {
                    id: '3',
                    title: 'Connections',
                    type: 'item',
                    url: '/connections',
                    icon: LanIcon,
                },
            ],
        };

        //

        const adminItems = {
            id: 'admin',
            title: 'Admin Tools',
            type: 'group',
            children: [],
        };

        if (auth.users) {
            adminItems.children.push({
                id: '1',
                title: 'Users',
                type: 'item',
                url: '/users',
                icon: PersonIcon,
            });
        }

        if (auth.clinics) {
            adminItems.children.push({
                id: '2',
                title: 'Clinics',
                type: 'item',
                url: '/clinics',
                icon: HealthAndSafetyIcon,
            });
        }

        if (auth.labs) {
            adminItems.children.push({
                id: '3',
                title: 'Labs',
                type: 'item',
                url: '/labs',
                icon: LocalPharmacyIcon,
            });
        }

        //

        const settingItems = {
            id: 'setting',
            title: 'Settings',
            type: 'group',
            children: [],
        };

        if (auth.profile) {
            settingItems.children.push({
                id: '1',
                title: 'Change Password',
                type: 'item',
                url: '/change-password',
                icon: LockIcon,
            });
        }

        setMenuItems([dashboardItems, adminItems, settingItems]);
    }, []);

    return menuItems
        .filter((item) => item.children.length)
        .map((item) => <NavGroup key={item.id} item={item} />);
}

export default MenuList;
