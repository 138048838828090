import './styles.scss';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import logo from '../../../assets/images/ori_logo.png';

function Branding({ label }) {
    const LabelStyled = styled(Typography)(({ theme }) => ({
        paddingLeft: '4px',
        fontSize: '17px',
        margin: '6px 0 0 6px',
        [theme.breakpoints.up('sm')]: {
            margin: '6px 0 0 25px',
            paddingLeft: '25px',
            fontSize: '30px',
            fontWeight: '500',
        },
    }));

    return (
        <Grid display="flex" flexDirection="row" alignItems="center" container className="dashboard-branding" sx={{ flexGrow: 1 }}>
            <Link to="/cases">
                <Grid container item display="flex">
                    <Grid item className="dashboard-branding-img">
                        <img src={logo} alt="Ori Dental Scanner Dashboard" />
                    </Grid>
                    <Grid item className="dashboard-branding-name">
                        <h1>Ori</h1>
                        <span>Dental Scanner</span>
                    </Grid>
                </Grid>
            </Link>
            <Grid item>
                <LabelStyled className="label" variant="h6" component="div">
                    { label }
                </LabelStyled>
            </Grid>
        </Grid>
    );
}

Branding.propTypes = {
    label: PropTypes.string.isRequired,
};

export default Branding;
