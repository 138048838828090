import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { http } from '../../helpers';
import './styles.scss';

class Image extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blob: '',
        };
    }

    componentDidMount() {
        const { uri } = this.props;
        http.getBlob(uri, 'image/png')
            .then((response) => {
                const image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                this.setState({ blob: `data:${response.headers['content-type'].toLowerCase()};base64,${image}` });
            })
            .catch((error) => console.warn('oh no!', error));
    }

    render() {
        const { blob } = this.state;
        const { name } = this.props;
        return (
            <img className={name} src={blob} alt={name.replace(/^\w/, (c) => c.toUpperCase())} />
        );
    }
}

Image.propTypes = {
    uri: PropTypes.string,
    name: PropTypes.string,
};

Image.defaultProps = {
    uri: '',
    name: '',
};

export default Image;
