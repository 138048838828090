import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function TableHeading({ heading, span }) {
    return (
        <div className={`table-heading span-${span}`}>
            <span>{heading}</span>
        </div>
    );
}

TableHeading.propTypes = {
    heading: PropTypes.string.isRequired,
    span: PropTypes.string.isRequired,
};

export default TableHeading;
