import { LAB, CLINIC, ADMIN } from './constants';

export const getRole = () => {
    const scopes = localStorage.getItem('scope');
    const labScope = scopes?.includes('connections.invitations.labs:write');
    const clinicScope = scopes?.includes('connections.invitations.clinics:write');
    if (!labScope) return LAB;
    if (!clinicScope) return CLINIC;
    return ADMIN;
};

export { default as http } from './http';
export { default as getRefreshedToken } from './getRefreshedToken';
export { default as updateLocalStorage } from './updateLocalStorage';
export { default as invalidPhone } from './invalidPhone';
export { default as stripNonNumbers } from './stripNonNumbers';
export * from './constants';
