import PropTypes from 'prop-types';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getRefreshedToken, updateLocalStorage, REFRESH_BUFFER_SECONDS } from '../../helpers';

// check if a token exists and is not expired
// if a rule passed, makes sure it exists in user scope
const hasAccess = (rule) => {
    const { token, exp, scope } = { ...localStorage };
    const scopeArray = (scope || '').split(' ');
    const hasScope = (x) => scopeArray.includes(x);
    if (token && exp) {
        if (exp < new Date().getTime() / 1000 + REFRESH_BUFFER_SECONDS) {
            return getRefreshedToken()
                .then((response) => {
                    updateLocalStorage(response);
                })
                .catch(() => {
                    localStorage.clear();
                    window.location.href = '/login';
                });
        }
        return rule ? hasScope(rule) : true;
    }
    return false;
};

function ProtectedRoute({ rule }) {
    return hasAccess(rule) ? <Outlet /> : <Navigate to={rule ? '/' : '/login'} />;
}

ProtectedRoute.propTypes = {
    rule: PropTypes.string,
};

ProtectedRoute.defaultProps = {
    rule: '',
};

export default ProtectedRoute;
