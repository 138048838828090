import { http } from '../../helpers';
import { fetchUsers } from './fetchUsers';

export function disableUser(id, disable) {
    const action = disable === true ? 'disable' : 'enable';
    return (dispatch) => {
        const relativeUrl = `/case-api/users/${id}/${action}`;
        http.post(relativeUrl)
            .then(() => {
                dispatch(fetchUsers());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
