// import { connections } from '../../mock/connections.mock.json';

// const initialState = connections;
const initialState = {
    connections: null,
    chosenClinic: null,
    chosenLab: null,
    paginatin: {
        number: 0,
        totalPages: 1,
        first: true,
        last: true,
    },
};

export const clearConnectionsAction = () => ({
    type: 'connections/CLEAR_CONNECTIONS',
});

export const chooseClinicAction = (chosenClinic) => ({
    type: 'connections/CHOOSE_CLINIC',
    chosenClinic,
});
export const chooseLabAction = (chosenLab) => ({
    type: 'connections/CHOOSE_LAB',
    chosenLab,
});

export const addConnection = (form) => async (dispatch) => {
    dispatch({ type: 'ADD_CONNECTION', form });
};

export default (state = initialState, action) => {
    const { type: actionType, ...changes } = action;
    switch (action.type) {
        case 'INITIALIZE_CONNECTIONS':
            return state;
        case 'ADD_CONNECTION':
            return state;
        case 'connections/CHOOSE_CLINIC':
        case 'connections/CHOOSE_LAB': {
            if (!changes.chosenLab && !changes.chosenClinic) return { ...state, ...changes, connections: [] };
            return { ...state, ...changes };
        }
        case 'connections/UPDATE_STATE':
            return { ...state, ...changes };
        case 'connections/CLEAR_CONNECTIONS':
            return initialState;
        default:
            return state;
    }
};
