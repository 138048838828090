import { http } from '../../helpers';
import { fetchUsers } from './fetchUsers';
import { disableUser } from './disableUser';

export function updateUser(userToSave, enabled) {
    return (dispatch) => {
        const relativeUrl = `/case-api/users/${userToSave.id}`;
        http.put(relativeUrl, userToSave)
            .then((res) => {
                if (res.status === 200) {
                    if (enabled !== userToSave.enabled) dispatch(disableUser(userToSave.id, !enabled));
                    else dispatch(fetchUsers());
                } else {
                    console.error(res);
                }
            })
            .catch((error) => {
                console.warn(error);
            });
    };
}
