import PropTypes from 'prop-types';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuList from './MenuList';
/* eslint-disable */ 

function Sidebar({ drawerOpen, drawerClose }) {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const drawerWidth = 240;

    return (
        <Box
            component="nav"
            sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}
            aria-label="mailbox folders"
        >
            <Drawer
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerClose}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        padding: '14px',
                        [theme.breakpoints.up('md')]: {
                            top: '88px',
                        },
                    },
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <MenuList />
            </Drawer>
        </Box>
    );
}

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool.isRequired,
    drawerClose: PropTypes.func.isRequired,
};

export default Sidebar;
