const initialState = {
    allLabs: null,
    labs: null,
    pagination: {
        number: 0,
        totalPages: 0,
        first: null,
        last: null,
    },
};
export default (state = initialState, action) => {
    const { type: actionType, ...changes } = action;
    switch (action.type) {
        case 'labs/UPDATE_STATE':
            return { ...state, ...changes };
        default:
            return state;
    }
};
