import {
    createStore,
    combineReducers,
    applyMiddleware,
    compose,
} from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

// import reducers
import {
    global,
    cases,
    caseDetails,
    clinics,
    filteredCases,
    connections,
    labs,
    users,
    menu,
} from './reducers';

export default (state = {}) => {
    // populate middleware
    const middleware = [
        thunkMiddleware,
    ];

    // include all reducers
    const reducers = combineReducers({
        global,
        cases,
        caseDetails,
        clinics,
        filteredCases,
        connections,
        labs,
        users,
        menu,
    });

    // add logger to middleware
    if (process.env.NODE_ENV !== 'production') {
        if (typeof window !== 'undefined') {
            middleware.push(createLogger({ collapsed: true }));
        }
    }

    // add enhancers to allow for reduxDevTools
    // https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
    const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

    // add middleware to enhancers
    const enhancer = composeEnhancers(
        applyMiddleware(...middleware),
    );

    return createStore(reducers, state, enhancer);
};
