import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function TableDataWrapper({ children, onClick }) {
    return (
        <div className="table-data-wrapper" onClick={onClick} role="presentation">
            {
                children
            }
        </div>
    );
}

TableDataWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    onClick: PropTypes.func,
};

TableDataWrapper.defaultProps = {
    onClick: null,
};

export default TableDataWrapper;
