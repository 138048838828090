import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function TableRowDrop({ children, open, openHeight }) {
    return (
        <div style={{ height: open ? openHeight : '' }} className={`table-row-drop ${open ? 'open' : ''}`}>
            {
                children
            }
        </div>
    );
}

TableRowDrop.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    open: PropTypes.bool,
    openHeight: PropTypes.string,
};

TableRowDrop.defaultProps = {
    open: false,
    openHeight: '',
};

export default TableRowDrop;
