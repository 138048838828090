import { http } from '../../helpers';
import { updateLabsAction, updateAllLabsAction } from '../actions';

export function fetchLabs(options = {}) {
    return (dispatch) => {
        const relativeUrl = `/case-api/labs?size=${options.size || 10}&page=${options.page || 0}&sort=name,id&search=${options.search || ''}&archived=false`;
        http.get(relativeUrl)
            .then(({ data }) => {
                if (options.size >= 1000) {
                    dispatch(updateAllLabsAction(data));
                } else {
                    dispatch(updateLabsAction(data));
                }
            })
            .catch((error) => {
                console.warn(error);
            });
    };
}
