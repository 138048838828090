import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function Column({ children, styles, span, divider }) {
    return (
        <div className={`column ${styles} span-${span} ${!divider ? 'no-divider' : ''}`}>
            {
                children
            }
        </div>
    );
}

Column.propTypes = {
    children: PropTypes.node.isRequired,
    span: PropTypes.string,
    divider: PropTypes.bool,
    styles: PropTypes.string,
};

Column.defaultProps = {
    divider: true,
    styles: '',
    span: '',
};

export default Column;
