import PropTypes from 'prop-types';
import { Switch, FormControlLabel } from '@mui/material';

function Toggle({ onChange, label, checked, defaultChecked }) {
    if (checked !== null) {
        return (
            <FormControlLabel
                label={label}
                control={
                    (
                        <Switch
                            checked={checked}
                            onChange={onChange}
                        />
                    )
                }
            />
        );
    }
    return (
        <FormControlLabel
            label={label}
            control={
                (
                    <Switch
                        defaultChecked={defaultChecked}
                        onChange={onChange}
                    />
                )
            }
        />
    );
}

Toggle.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
};

Toggle.defaultProps = {
    checked: null,
    defaultChecked: null,
};

export default Toggle;
