import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { http } from '../../helpers';
import './styles.scss';

import biteThmb from '../../assets/images/bite_thumbnail.png';
import upperThmb from '../../assets/images/upper_thumbnail.png';
import lowerThmb from '../../assets/images/lower_thumbnail.png';

class ScanThumbnail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blob: '',
            hasThumbnail: false,
        };
    }

    componentDidMount() {
        const { uri, name } = this.props;
        const type = `?type=${name}`;

        const { scope } = localStorage;
        const scopeArray = (scope || '').split(' ');
        const hasAccess = (rule) => scopeArray.includes(rule);
        const canReadThmb = hasAccess('cases.thumbnails:read');

        if (canReadThmb) {
            http.getBlob(uri + type, 'image/png')
                .then((response) => {
                    const image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                    this.setState({ blob: `data:${response.headers['content-type'].toLowerCase()};base64,${image}`, hasThumbnail: true });
                })
                .catch((error) => {
                    if (error.toJSON().status === 404) { this.setState({ hasThumbnail: false }); } else console.warn('oh no!');
                });
        }
    }

    render() {
        const { blob, hasThumbnail } = this.state;
        const { name } = this.props;

        const src = name === 'bite'
            ? biteThmb
            : name === 'upper'
                ? upperThmb
                : lowerThmb;

        return (
            <div>
                {
                    hasThumbnail
                        ? <img className={`${name} thumbnail`} src={blob} alt={name.replace(/^\w/, (c) => c.toUpperCase())} />
                        : <img src={src} alt="Scan Thumbnail" />
                }
            </div>
        );
    }
}

ScanThumbnail.propTypes = {
    uri: PropTypes.string,
    name: PropTypes.string,
};

ScanThumbnail.defaultProps = {
    uri: '',
    name: '',
};

export default ScanThumbnail;
