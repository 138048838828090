/* eslint-disable camelcase */
import jwt_decode from 'jwt-decode';

export default function updateLocalStorage({ data }) {
    localStorage.clear();
    const decodedToken = jwt_decode(data.access_token);

    localStorage.setItem('token', data.access_token);
    localStorage.setItem('refreshToken', data.refresh_token);
    localStorage.setItem('exp', decodedToken.exp);
    localStorage.setItem('scope', decodedToken.scope);
}
