import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

const Input = styled(TextField)({
    width: '100%',
    '& label.Mui-focused': {
        color: 'black',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'black',
        },
        '&:hover fieldset': {
            borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'black',
        },
    },
    '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
        color: 'black',
    },
    // '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
    //     borderRadius: 7,
    // },
});

export default Input;
