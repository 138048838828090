import './styles.scss';
import { useState, useEffect } from 'react';
import {
    Alert,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Tooltip,
    InputAdornment,
    IconButton,
    FormHelperText,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Header, ClinicPicker, LabPicker, Input, Select } from '../../components';
import { http } from '../../helpers';

const CLINIC_USERS = 'CLINIC_USERS';
const LAB_USERS = 'LAB_USERS';
function CreateUser() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [groups, setGroups] = useState([]);
    const [groupsError, setGroupsError] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedLab, setSelectedLab] = useState('');
    const [selectedLabError, setSelectedLabError] = useState('');
    const [selectedClinic, setSelectedClinic] = useState('');
    const [selectedClinicError, setSelectedClinicError] = useState('');
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Something went wrong');
    const [showPassword, setShowPassword] = useState(false);

    const clearForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setUsername('');
        setPassword('');
        setSelectedGroup('');
        setSelectedClinic('');
        setSelectedLab('');
    };

    const getGroups = () => {
        const url = '/case-api/groups';
        http
            .get(url)
            .then(({ data }) => setGroups(data.sort()))
            .catch((error) => console.warn(error));
    };

    const onSelectGroup = (e) => {
        const { value } = e.target;
        if (value) setGroupsError('');
        setSelectedGroup(value);
    };

    const onClickPasswordEye = () => {
        setShowPassword(!showPassword);
    };

    const isNotValid = () => !username || !email || (selectedGroup === CLINIC_USERS && !selectedClinic) || (selectedGroup === LAB_USERS && !selectedLab) || !password || !selectedGroup;

    const onSubmit = () => {
        if (isNotValid()) {
            if (!email) setEmailError('Email is required');
            if (!username) setUsernameError('Username is required');
            if (!password) setPasswordError('Password is required');
            if (!selectedGroup) setGroupsError('Group is required');
            if (selectedGroup === CLINIC_USERS && !selectedClinic) setSelectedClinicError('Please select a clinic');
            if (selectedGroup === LAB_USERS && !selectedLab) setSelectedLabError('Please select a lab');
            return null;
        }
        const url = '/case-api/users';
        const body = {
            firstName,
            lastName,
            email,
            password,
            username,
            group: selectedGroup,
        };

        if (selectedGroup === CLINIC_USERS) {
            if (!selectedClinic) return setFail(true);
            body.clinicId = selectedClinic;
        }
        if (selectedGroup === LAB_USERS) {
            if (!selectedLab) return setFail(true);
            body.labId = selectedLab;
        }

        return http
            .post(url, body)
            .then(() => {
                setSuccess(true);
                setFail(false);
                clearForm();
            })
            .catch(({ request }) => {
                const response = JSON.parse(request?.response);
                const error = response?.error;
                if (error) setErrorMessage(error);
                setSuccess(false);
                setFail(true);
            });
    };

    const onSetEmail = (evt) => {
        const val = evt.target.value;
        if (val) setEmailError('');
        setEmail(val);
    };
    const onSetUsername = (evt) => {
        const val = evt.target.value;
        if (val) setUsernameError('');
        setUsername(val);
    };
    const onSetPassword = (evt) => {
        const val = evt.target.value;
        if (val) setPasswordError('');
        setPassword(val);
    };
    const onSelectClinic = (clinic) => {
        if (clinic) setSelectedClinicError('');
        setSelectedClinic(clinic);
    };
    const onSelectLab = (lab) => {
        if (lab) setSelectedLabError('');
        setSelectedLab(lab);
    };

    const getTooltip = () => {
        if (!email) return 'Email required';
        if (!username) return 'Username required';
        if (!password) return 'Password required';
        if (!selectedGroup) return 'Group required';
        if (selectedGroup === CLINIC_USERS && !selectedClinic) return 'Please select a clinic';
        if (selectedGroup === LAB_USERS && !selectedLab) return 'Please select a lab';
        return '';
    };

    useEffect(() => {
        getGroups();
    }, []);

    return (
        <>
            <Header label="Create User" />
            <main className="create-user-container">
                <Grid spacing={4} container direction="column" alignItems="center">
                    <Grid
                        className="form-field"
                        item
                        style={{ height: '50px', paddingTop: '0px' }}
                    >
                        {success && (
                            <Alert>
                                Success! User created
                            </Alert>
                        )}
                        {fail && (
                            <Alert severity="error">
                                { errorMessage }
                            </Alert>
                        )}
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Email"
                            variant="outlined"
                            value={email}
                            required
                            error={emailError}
                            helperText={emailError}
                            onChange={onSetEmail}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Username"
                            variant="outlined"
                            value={username}
                            error={usernameError}
                            helperText={usernameError}
                            required
                            onChange={onSetUsername}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="First Name"
                            variant="outlined"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Last Name"
                            variant="outlined"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            required
                            error={passwordError}
                            helperText={passwordError}
                            variant="outlined"
                            value={password}
                            onChange={onSetPassword}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={onClickPasswordEye}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth>
                            <InputLabel style={{ color: 'black' }}>Group</InputLabel>
                            <Select
                                className="form-field"
                                label="Group"
                                required
                                error={groupsError}
                                value={selectedGroup}
                                onChange={onSelectGroup}
                            >
                                {groups.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText><font color="#d53939">{groupsError}</font></FormHelperText>
                        </FormControl>
                    </Grid>
                    {selectedGroup === CLINIC_USERS && (
                        <Grid item>
                            <ClinicPicker
                                required
                                selectedClinic={selectedClinic}
                                onSelectClinic={onSelectClinic}
                                errorText={selectedClinicError}
                            />
                        </Grid>
                    )}
                    {selectedGroup === LAB_USERS && (
                        <Grid item>
                            <LabPicker
                                required
                                selectedLab={selectedLab}
                                onSelectLab={onSelectLab}
                                errorText={selectedLabError}
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <Tooltip title={getTooltip()}>
                            <span>
                                <Button
                                    onClick={onSubmit}
                                    variant="outlined"
                                    style={{ border: '1px solid black', color: 'black' }}
                                >
                                    Submit
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            </main>
        </>
    );
}
export default CreateUser;
