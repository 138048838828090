/* eslint-disable react/jsx-indent-props */

import PropTypes from 'prop-types';
import { TableRow, TableData, TableDataWrapper, TableRowDrop } from '../..';
import ClinicDetail from '../ClinicDetail';

function ClinicRow({ data, onClickRow, open }) {
    return (
        <TableRow>
            <TableDataWrapper onClick={onClickRow}>
                <TableData data={data.name} span="3" />
                <TableData data={data.city} span="2" />
                <TableData data={data.state} span="1" isCapitalized={false} />
                <TableData data={data.email} span="2" />
                <TableData data={data.officePhone} span="2" />
            </TableDataWrapper>
            <TableRowDrop open={open} openHeight="200px">
                <ClinicDetail clinic={data} />
            </TableRowDrop>
        </TableRow>
    );
}

ClinicRow.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        email: PropTypes.string,
        officePhone: PropTypes.string,
        cellPhone: PropTypes.string,
    }).isRequired,
    onClickRow: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default ClinicRow;
