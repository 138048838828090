import PropTypes from 'prop-types';
import './styles.scss';

function Pagination({ pagination: { first, last, totalPages, number }, onChangePage }) {
    const goFirst = () => onChangePage(0);
    const goBack = () => onChangePage(number - 1);
    const goNext = () => onChangePage(number + 1);
    const goLast = () => onChangePage(totalPages - 1);

    const template = (
        <div className="pagination-container">
            <div className="text">
                {number + 1}
                {' '}
                of
                {' '}
                {totalPages}
            </div>
            <div>
                <button type="button" className="btn pagination" onClick={goFirst} disabled={first}>
                    <i className="material-icons">keyboard_double_arrow_left</i>
                </button>
                <button type="button" className="btn pagination" onClick={goBack} disabled={first}>
                    <i className="material-icons">keyboard_arrow_left</i>
                </button>
                <button type="button" className="btn pagination" onClick={goNext} disabled={last}>
                    <i className="material-icons">keyboard_arrow_right</i>
                </button>
                <button type="button" className="btn pagination" onClick={goLast} disabled={last}>
                    <i className="material-icons">keyboard_double_arrow_right</i>
                </button>
            </div>
        </div>
    );
    return totalPages ? template : null;
}

Pagination.defaultProps = {
    pagination: {
        first: false,
        last: false,
        totalPages: 0,
        number: 0,
    },
};

Pagination.propTypes = {
    onChangePage: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
        first: PropTypes.bool,
        last: PropTypes.bool,
        totalPages: PropTypes.number,
        number: PropTypes.number,
    }),
};

export default Pagination;
