import { useState } from 'react';
import PropTypes from 'prop-types';
import LabRow from './LabRow';
import {
    Table,
    TableHead,
    TableBody,
    TableHeading,
    TableRow,
} from '..';

function Labs({ labs }) {
    const [openRow, setOpenRow] = useState('');

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeading heading="Name" span="2" />
                    <TableHeading heading="Company" span="3" />
                    <TableHeading heading="Email" span="2" />
                    <TableHeading heading="Office Phone" span="2" />
                    <TableHeading heading="Cell Phone" span="2" />
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    labs && labs.map((c) => (
                        <LabRow
                            data={c}
                            onClickRow={() => setOpenRow(openRow === c.id ? '' : c.id)}
                            open={openRow === c.id}
                            key={c.id}
                        />
                    ))
                }
            </TableBody>
        </Table>
    );
}

Labs.propTypes = {
    labs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        company: PropTypes.string,
        email: PropTypes.string,
        officePhone: PropTypes.string,
        cellPhone: PropTypes.string,
        archived: PropTypes.bool,
    })),
};

Labs.defaultProps = {
    labs: [],
};

export default Labs;
