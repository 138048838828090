/* eslint-disable no-constant-condition */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { DayPickerRangeController } from 'react-dates';

class DateRange extends Component {
    constructor() {
        super();
        this.state = {
            show: false,
            startDate: null,
            endDate: null,
            focusedInput: false ? 'endDate' : 'startDate',
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }

    handleDateChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate });
    };

    onFocusChange = (focusedInput) => {
        this.setState({ focusedInput: !focusedInput ? 'startDate' : focusedInput });
    };

    handleClickOutside = () => {
        this.setState({ show: false });
    };

    handleClick = (e) => {
        const { onHandleDateRangeFiltering } = this.props;
        const { startDate, endDate } = this.state;

        // if the apply button was clicked
        if (e.target.name === 'apply') {
            onHandleDateRangeFiltering(startDate, endDate);
        }

        // if something OUTSIDE the datepicker or the cancel button is clicked
        if (this.done.contains(e.target) || e.target.name === 'cancel') {
            this.handleClickOutside();
            return;
        }

        // if something INSIDE the datepicker is clicked
        if (this.node.contains(e.target)) {
            return;
        }

        // something else has happened
        this.handleClickOutside();
    };

    reset = () => {
        this.setState({ startDate: null, endDate: null });
    };

    getTimeframe = () => {
        const { startDate, endDate } = this.state;
        const a = moment(endDate);
        const b = moment(startDate);
        const y = a.diff(b, 'year');
        b.add(y, 'years');
        const m = a.diff(b, 'months');
        b.add(m, 'months');
        const d = a.diff(b, 'days') + 1;
        const years = y > 0 ? `${y} year${y > 1 ? 's' : ''}` : '';
        const months = m > 0 ? `${m} month${m > 1 ? 's' : ''}` : '';
        const days = d > 0 ? `${d} day${d > 1 ? 's' : ''}` : '';
        return `${years} ${months} ${days}`;
    };

    render() {
        const {
            show,
            startDate,
            endDate,
            focusedInput,
        } = this.state;
        // const { onHandleDatePerodicFiltering } = this.props;

        return (
            <div className="date-range-container">
                <div className={`dropdown has-icon ${show && 'show'}`} role="presentation" onClick={() => this.setState({ show: true })}>
                    <i style={{ marginTop: '6px' }} className="material-icons">date_range</i>
                    <span className="input-icon">Date Range</span>
                    <div className="drop" ref={(node) => { this.node = node; }}>

                        <div className="drop-body">
                            {/* <div className="pane button-array">
                                <ul>
                                    <li onClick={() => onHandleDatePerodicFiltering('lastWeek')} role="presentation">Last Week</li>
                                    <li onClick={() => onHandleDatePerodicFiltering('lastMonth')} role="presentation">Last Month</li>
                                    <li onClick={() => onHandleDatePerodicFiltering('lastThreeMonths')} role="presentation">Last 3 Months</li>
                                    <li onClick={() => onHandleDatePerodicFiltering('lastSixMonths')} role="presentation">Last 6 Months</li>
                                    <li onClick={() => onHandleDatePerodicFiltering('lastYear')} role="presentation">Last Year</li>
                                    <li onClick={() => onHandleDatePerodicFiltering('lastTwoYears')} role="presentation">Last 2 Years</li>
                                    <li onClick={() => onHandleDatePerodicFiltering('lastFiveYears')} role="presentation">Last 5 Years</li>
                                </ul>
                            </div> */}
                            <div className="pane date-picker">
                                <div className="date-picker-header">
                                    <span className="date-reset hover" role="presentation" onClick={() => this.reset()}>Reset</span>
                                    <span className="date-marker">
                                        <i className="material-icons calendar">date_range</i>
                                        {
                                            startDate
                                                ? moment(startDate).format('L')
                                                : moment().format('L')
                                        }
                                        <i className="material-icons chevron-left">keyboard_arrow_left</i>
                                        <i className="material-icons chevron-right">keyboard_arrow_right</i>
                                    </span>
                                    <span className="date-marker">
                                        <i className="material-icons calendar">date_range</i>
                                        {
                                            endDate
                                                ? moment(endDate).format('L')
                                                : moment().format('L')
                                        }
                                        <i className="material-icons chevron-left">keyboard_arrow_left</i>
                                        <i className="material-icons chevron-right">keyboard_arrow_right</i>
                                    </span>
                                </div>
                                <DayPickerRangeController startDate={startDate} endDate={endDate} onDatesChange={this.handleDateChange} focusedInput={focusedInput} onFocusChange={this.onFocusChange} numberOfMonths={2} />
                            </div>
                        </div>

                        <div className="drop-footer">
                            <div className="drop-footer-label">
                                {
                                    endDate
                                    && (
                                        <>
                                            <span>Showing date range</span>
                                            <i className="material-icons">keyboard_arrow_left</i>
                                            <span>
                                                {
                                                    this.getTimeframe()
                                                }
                                            </span>
                                            <i className="material-icons">keyboard_arrow_right</i>
                                        </>
                                    )
                                }
                            </div>
                            <div className="drop-footer-controls">
                                <button type="button" className="btn" name="cancel" onClick={() => this.setState({ show: true })} ref={(node) => { this.done = node; }}>Cancel</button>
                                <button type="button" className="btn btn-dark" name="apply" onClick={() => this.setState({ show: true })} ref={(node) => { this.done = node; }}>Apply</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

DateRange.propTypes = {
    onHandleDateRangeFiltering: PropTypes.func.isRequired,
    // onHandleDatePerodicFiltering: PropTypes.func.isRequired,
};

export default DateRange;
