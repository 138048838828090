import './styles.scss';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import React from 'react';
import Line from './Line';

const BorderLinearProgress = styled(LinearProgress)(
    ({ background }) => ({
        height: 50,
        borderRadius: '5px',
        border: '1px dotted grey',
        backgroundColor: 'white',
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: background,
        },
    }),
);

function MonthlyCostWidget({ count, cost, tiers }) {
    const totalQuantity = tiers.reduce((acc, x) => acc + x.quantity, 0);
    const percentageArr = tiers.map((tier) => `${(tier.quantity * 100) / totalQuantity}%`);
    const getCost = (index) => `$${tiers[index].cost}`;
    const perc = (Math.min(count, totalQuantity) / totalQuantity) * 100;
    const rangeArray = tiers.reduce(
        (acc, tier, index) => {
            acc.push(acc[index] + tier.quantity);
            return acc;
        },
        [0],
    );
    const getValue = (i) => (count <= rangeArray[i - 1]
        ? 0
        : count <= rangeArray[i]
            ? ((count - rangeArray[i - 1]) / (rangeArray[i] - rangeArray[i - 1]))
        * 100
            : 100);

    return (
        <Box display="flex" className="widget-container" sx={{ mb: 10, mt: 10, position: 'relative' }}>
            <Line count={count} cost={cost} perc={perc} />
            <Box sx={{ width: percentageArr[0] }}>
                <div className="text first">{rangeArray[0]}</div>
                <BorderLinearProgress
                    variant="determinate"
                    value={count <= rangeArray[1] ? (count / rangeArray[1]) * 100 : 100}
                    background="#385592"
                />
                <div className="text cost text-center">{getCost(0)}</div>
            </Box>
            <Box className="bar-container" sx={{ width: percentageArr[1] }}>
                <div className="text">{rangeArray[1]}</div>
                <BorderLinearProgress
                    value={getValue(2)}
                    variant="determinate"
                    background="#778bb2"
                />
                <div className="text cost text-center">{getCost(1)}</div>
            </Box>
            <Box className="bar-container" sx={{ width: percentageArr[2] }}>
                <div className="text">{rangeArray[2]}</div>
                <BorderLinearProgress
                    variant="determinate"
                    value={getValue(3)}
                    background="#b8c6e4"
                />
                <div className="text cost text-center">{getCost(2)}</div>
            </Box>
            <Box className="bar-container" sx={{ width: percentageArr[3] }}>
                <div className="text">{rangeArray[3]}</div>
                <BorderLinearProgress
                    variant="determinate"
                    value={getValue(4)}
                    background="#d7e3fa"
                />
                <div className="text cost text-center">{getCost(3)}</div>
            </Box>
            <Box className="bar-container" sx={{ width: percentageArr[4] }}>
                <div className="text">{rangeArray[4]}</div>
                <BorderLinearProgress
                    variant="determinate"
                    value={getValue(5)}
                    background="#ebf1ff"
                />
                <div className="text cost text-center">{getCost(4)}</div>
            </Box>
        </Box>
    );
}

MonthlyCostWidget.propTypes = {
    count: PropTypes.number.isRequired,
    cost: PropTypes.number.isRequired,
    tiers: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default MonthlyCostWidget;
