import './styles.scss';
import { useState } from 'react';
import { Alert, Button, Grid, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Header, Input, PhoneInput } from '../../components';
import { http, invalidPhone } from '../../helpers';

function CreateClinic() {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [description, setDescription] = useState('');

    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');

    const [email, setEmail] = useState('');
    const [officePhone, setOfficePhone] = useState('');
    const [officePhoneError, setOfficePhoneError] = useState('');
    const [cellPhone, setCellPhone] = useState('');
    const [cellPhoneError, setCellPhoneError] = useState('');
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Something went wrong');

    const onSubmit = () => {
        if (!name) return setNameError('Name is required');
        if (invalidPhone(officePhone)) return setOfficePhoneError('Invalid office phone number');
        if (invalidPhone(cellPhone)) return setCellPhoneError('Invalid cell phone number');
        const url = '/case-api/clinics';
        const body = { name, description, address1, address2, city, state, zip, email, officePhone, cellPhone };

        return http
            .post(url, body)
            .then(() => {
                setSuccess(true);
                setFail(false);
                setTimeout(() => {
                    navigate('/clinics');
                }, 700);
            })
            .catch(({ request }) => {
                const response = JSON.parse(request?.response);
                const error = response?.error;
                if (error) setErrorMessage(error);
                setSuccess(false);
                setFail(true);
            });
    };
    const getTooltip = () => {
        if (!name) return 'Name required';
        return '';
    };
    const updateOfficePhone = (evt) => {
        const val = evt.target.value;
        if (!invalidPhone(val)) setOfficePhoneError('');
        setOfficePhone(val);
    };
    const updateCellPhone = (evt) => {
        const val = evt.target.value;
        console.log('FOO', invalidPhone(val));
        if (!invalidPhone(val)) setCellPhoneError('');
        setCellPhone(val);
    };

    return (
        <>
            <Header label="Create Clinic" />
            <main className="create-clinic-container">
                <Grid spacing={4} container direction="column" alignItems="center">
                    <Grid
                        className="form-field"
                        item
                        style={{ height: '50px', paddingTop: '0px' }}
                    >
                        {success && (
                            <Alert>
                                Success! Clinic created
                            </Alert>
                        )}
                        {fail && (
                            <Alert severity="error">
                                { errorMessage }
                            </Alert>
                        )}
                    </Grid>
                    <Grid item>
                        <Input
                            required
                            className="form-field"
                            label="Clinic Name"
                            variant="outlined"
                            error={!!nameError}
                            helperText={nameError}
                            value={name}
                            onChange={(e) => {
                                const val = e.target.value;
                                if (val) setNameError('');
                                setName(val);
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Description"
                            variant="outlined"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Address Line 1"
                            variant="outlined"
                            value={address1}
                            onChange={(e) => setAddress1(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Address Line 2"
                            variant="outlined"
                            value={address2}
                            onChange={(e) => setAddress2(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="City"
                            variant="outlined"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="State"
                            variant="outlined"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Zip"
                            variant="outlined"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className="form-field"
                            label="Email"
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <PhoneInput
                            label="Office Phone"
                            value={officePhone}
                            onChange={updateOfficePhone}
                            errorText={officePhoneError}
                        />
                    </Grid>
                    <Grid item>
                        <PhoneInput
                            label="Cell Phone"
                            value={cellPhone}
                            onChange={updateCellPhone}
                            errorText={cellPhoneError}
                        />
                    </Grid>
                    <Grid item>
                        <Tooltip title={getTooltip()}>
                            <span>
                                <Button
                                    onClick={onSubmit}
                                    variant="outlined"
                                    style={{ border: '1px solid black', color: 'black' }}
                                >
                                    Submit
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            </main>
        </>
    );
}
export default CreateClinic;
