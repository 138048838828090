import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

class Filter extends Component {
    constructor() {
        super();
        this.state = {
            show: false,
            selected: null,
            items: [
                {
                    id: 0,
                    label: 'All',
                    data: null,
                },
                {
                    id: 4,
                    label: 'Submitted',
                    data: 'Submitted',
                },
                {
                    id: 5,
                    label: 'Accepted',
                    data: 'Accepted',
                },
            ],
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }

    handleClick = (e) => {
        if (this.node.contains(e.target)) {
            return;
        }
        this.handleClickOutside();
    };

    handleClickOutside = () => {
        this.setState({ show: false });
    };

    toggle = () => {
        const { show } = this.state;
        this.setState({ show: !show });
    };

    handleSelect = (value) => {
        const { onHandleStatusFiltering } = this.props;
        this.setState({ selected: value });
        onHandleStatusFiltering(value);
    };

    render() {
        const { show, items, selected } = this.state;
        return (
            <div className="filter-container">
                <span className="container-label">Status</span>
                <div className={`dropdown ${show && 'show'}`} role="presentation" onClick={() => this.toggle()}>
                    <span>{!selected ? 'Select Status' : selected}</span>
                    <div className="drop" ref={(node) => { this.node = node; }}>
                        <ul>
                            {
                                (
                                    items.map((item) => <li role="presentation" key={item.id} onClick={() => this.handleSelect(item.data)}>{ item.label }</li>)
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

Filter.propTypes = {
    onHandleStatusFiltering: PropTypes.func.isRequired,
};

export default Filter;
