import { useState } from 'react';
import PropTypes from 'prop-types';
import UserRow from './UserRow';
import {
    Table,
    TableHead,
    TableBody,
    TableHeading,
    TableRow,
} from '..';

function UserList({ users }) {
    const [openRow, setOpenRow] = useState('');

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeading heading="Email" span="3" />
                    <TableHeading heading="Username" span="3" />
                    <TableHeading heading="First Name" span="2" />
                    <TableHeading heading="Last Name" span="2" />
                </TableRow>
            </TableHead>
            {users
                && (
                    <TableBody>
                        { users && users.map((c) => (
                            <UserRow
                                data={c}
                                onClickRow={() => setOpenRow(openRow === c.id ? '' : c.id)}
                                open={openRow === c.id}
                                key={c.id}
                            />
                        )) }
                    </TableBody>
                )}
        </Table>
    );
}

UserList.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        email: PropTypes.string,
        enabled: PropTypes.bool,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        username: PropTypes.string,
        clinicId: PropTypes.string,
        labId: PropTypes.string,
    })),
};

UserList.defaultProps = {
    users: [],
};

export default UserList;
