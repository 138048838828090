/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */

const defaultState = { oauthToken: 'd2ViYXBwOg==' };

const INITIALIZE_GLOBAL_STORAGE = 'INITIALIZE_GLOBAL_STORAGE';
const UPDATE_GLOBAL_STORAGE = 'UPDATE_GLOBAL_STORAGE';
const CLEAR_GLOBAL_STATE = 'CLEAR_GLOBAL_STATE';
const UPDATE_ROLE = 'UPDATE_ROLE';

export const initializeGlobalStore = () => (dispatch) => {
    dispatch({ type: INITIALIZE_GLOBAL_STORAGE, data: { ...localStorage } });
};

export const updateGlobalStore = (data) => (dispatch) => {
    dispatch({ type: UPDATE_GLOBAL_STORAGE, data });
};

export const updateRoleAction = (role) => ({ type: UPDATE_ROLE, role });

export const clearGlobalStateAction = () => ({ type: CLEAR_GLOBAL_STATE });

export default (state = {}, action) => {
    switch (action.type) {
        case INITIALIZE_GLOBAL_STORAGE:
            return {
                ...state,
                ...action.data,
            };
        case UPDATE_GLOBAL_STORAGE:
            return {
                ...state,
                ...action.data,
            };
        case UPDATE_ROLE:
            return {
                ...state,
                role: action.role,
            };
        case CLEAR_GLOBAL_STATE:
            return defaultState;
        default:
            return {
                ...state,
                ...defaultState,
            };
    }
};
