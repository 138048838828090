/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { deleteUser, updateUser } from '../../../redux/thunks';
import { disableUser } from '../../../redux/thunks/disableUser';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    LabPicker,
    ClinicPicker,
    Toggle,
} from '../..';
import './styles.scss';

function UserDetail({ user }) {
    const dispatch = useDispatch();
    const allClinics = useSelector((state) => state.clinics.allClinics);
    const allLabs = useSelector((state) => state.labs.allLabs);
    const [showConfirm, setShowConfirm] = useState(false);
    const [editing, setEditing] = useState(false);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [enabled, setEnabled] = useState(user.enabled);
    const [clinicName, setClinicName] = useState('');
    const [labName, setLabName] = useState('');

    useEffect(() => {
        setClinicName(allClinics?.find((c) => c.id === user.clinicId)?.name || user.clinicId);
    }, [user.clinicId, allClinics]);
    useEffect(() => {
        setLabName(allLabs?.find((l) => l.id === user.labId)?.name || user.labId);
    }, [user.labId, allLabs]);

    // TODO: fix duplicate calls
    const onConfirmDelete = () => dispatch(deleteUser(user.id));
    const onCloseModal = () => setShowConfirm(false);
    const onSaveData = (evt) => {
        if (email || firstName || lastName || username) {
            // TODO: see if blank values are allowable
            const userToSave = { ...user };
            if (email) userToSave.email = email;
            if (firstName) userToSave.firstName = firstName;
            if (lastName) userToSave.lastName = lastName;
            if (username) userToSave.username = username;
            dispatch(updateUser(userToSave, enabled));
            setEditing('');
        } else if (enabled !== user.enabled) {
            dispatch(disableUser(user.id, !enabled));
            setEditing('');
        } else {
            alert('No changes made.'); // eslint-disable-line no-alert
        }
    };
    const onClickEdit = () => {
        setEditing(true);
    };
    const onCancelEdit = () => setEditing(false);

    const updateFirstName = (evt) => setFirstName(evt.target.value);
    const updateLastName = (evt) => setLastName(evt.target.value);
    const updateEmail = (evt) => setEmail(evt.target.value);
    const updateUsername = (evt) => setUsername(evt.target.value);
    const updateEnabled = (evt) => setEnabled(evt.target.checked);

    return (
        <div style={{ position: 'relative' }}>
            <div className="details">
                { editing
                    ? (
                        <div className="user-form-container">
                            <TextField
                                className="form-field"
                                style={{ gridArea: 'email' }}
                                label="Email"
                                variant="outlined"
                                defaultValue={user.email}
                                onChange={updateEmail}
                            />
                            <TextField
                                className="form-field"
                                style={{ gridArea: 'firstName' }}
                                label="First Name"
                                variant="outlined"
                                defaultValue={user.firstName}
                                onChange={updateFirstName}
                            />
                            <TextField
                                className="form-field"
                                style={{ gridArea: 'lastName' }}
                                label="Last Name"
                                variant="outlined"
                                defaultValue={user.lastName}
                                onChange={updateLastName}
                            />
                            <TextField
                                className="form-field"
                                style={{ gridArea: 'username' }}
                                label="Username"
                                variant="outlined"
                                defaultValue={user.username}
                                onChange={updateUsername}
                            />
                            <LabPicker
                                disabled
                                style={{ gridArea: 'lab' }}
                                selectedLab={user.labId}
                                onSelectLab={() => null}
                            />
                            <ClinicPicker
                                disabled
                                style={{ gridArea: 'clinic' }}
                                selectedClinic={user.clinicId}
                            />
                            <Toggle
                                label="Enabled"
                                defaultChecked={user.enabled}
                                onChange={updateEnabled}
                                style={{ gridArea: 'enabled' }}
                            />
                        </div>
                    ) : (
                        <div className="details-view">
                            <div>
                                <span>Email</span>
                                <p>{user.email}</p>
                                <span>Username</span>
                                <p>{user.username}</p>
                                <span>Enabled</span>
                                <p>{String(user.enabled)}</p>
                            </div>
                            <div>
                                <span>First Name</span>
                                <p>{user.firstName}</p>
                                <span>Last Name</span>
                                <p>{user.lastName}</p>
                            </div>
                            <div>
                                <span>Lab</span>
                                <p>{labName}</p>
                                <span>Clinic</span>
                                <p>{clinicName}</p>
                            </div>
                        </div>
                    )}
            </div>
            <div className="edit-delete-container">
                { editing ? (
                    <button className="edit-btn" onClick={onSaveData} type="button">
                        <i className="material-icons clickable">save</i>
                    </button>
                )
                    : (
                        <button className="edit-btn" onClick={onClickEdit} type="button">
                            <i className="material-icons clickable">create</i>
                        </button>
                    ) }
                { editing && (
                    <button
                        className="delete-btn"
                        onClick={onCancelEdit}
                        type="button"
                    >
                        <i className="material-icons clickable">cancel</i>
                    </button>
                )}
            </div>
            <Modal show={showConfirm}>
                <ModalHeader closeHandler={onCloseModal}>
                    <span>Confirm</span>
                </ModalHeader>
                <ModalBody>
                    <span>{`Are you sure you want to delete this ${user.email}?`}</span>
                </ModalBody>
                <ModalFooter>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <button className="btn" type="button" onClick={onConfirmDelete}>Confirm</button>
                        <button className="btn" type="button" onClick={onCloseModal}>Cancel</button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}

UserDetail.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        username: PropTypes.string,
        clinicId: PropTypes.string,
        labId: PropTypes.string,
        email: PropTypes.string,
        enabled: PropTypes.bool,
    }),
};

UserDetail.defaultProps = {
    user: {},
};
export default UserDetail;
