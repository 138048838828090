import React from 'react';
import PropTypes from 'prop-types';

function ModalBody({ children }) {
    return (
        <div className="modal-body">
            {
                children
            }
        </div>
    );
}

ModalBody.propTypes = {
    children: PropTypes.element,
};

ModalBody.defaultProps = {
    children: null,
};

export default ModalBody;
