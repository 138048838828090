/* eslint-disable no-unused-vars */
import './styles.scss';
import FileSaver from 'file-saver';
import InnerImageZoom from 'react-inner-image-zoom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Grid, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { StlViewer } from 'react-stl-viewer';
import { styled } from '@mui/material/styles';
import { Modal, Spinner, ScanThumbnail, PlyViewer } from '../../..';
import { http } from '../../../../helpers';

const buttonStyle = { border: '1px solid black', color: 'black', textTransform: 'none', width: '100px' };
const viewerStyle = { width: '690px', height: '400px', padding: '10px' };

const ToggleStyled = styled(ToggleButton)({
    '&:disabled': {
        opacity: '0.2',
        cursor: 'not-allowed',
        pointerEvents: 'all !important',
    },
});

class Renders extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
            showAlert: false,
            scan: null,
            fetching: false,
            activeBtn: '',
            rotationX: 0,
            rotationY: 0,
            scanToggle: null,
        };
    }

    fetchScan = (uri) => {
        this.setState({ fetching: true });
        http.getBlob(uri, 'application/sla')
            .then((response) => {
                const image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                const blob = `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
                this.setState({ scan: blob, fetching: false });
            })
            .catch(() => {
                console.warn('oh no!');
                this.setState({ fetching: false });
            });
    };

    getScan = (render) => {
        const { scans } = this.props;
        return scans.find((s) => s.label === render) || scans[0];
    };

    activateTab = (render) => {
        let newToggleVal = null;
        if (!this.isDisabled('thumbnail', render)) newToggleVal = 'thumbnail';
        else if (!this.isDisabled('ply', render)) newToggleVal = 'ply';
        else if (!this.isDisabled('stl', render)) newToggleVal = 'stl';

        this.setState({ scanToggle: newToggleVal }, () => {
            this.loadFile(render);
        });
    };

    loadFile = (render) => {
        const { scanToggle } = this.state;
        this.resetState();

        const activeScan = this.getScan(render);
        if (!activeScan) return;

        const url = scanToggle === 'stl'
            ? activeScan?.stlUrl
            : scanToggle === 'ply'
                ? activeScan?.plyUrl
                : `${activeScan.thumbnailUrl}?type=${render}`;
        if (url) this.fetchScan(url);
        this.setState({ activeBtn: render, showModal: true });
    };

    resetState = () => {
        this.setState({
            activeBtn: '',
            scan: null,
        });
    };

    handleStlDownload = () => {
        const { id } = this.props;
        const { scan, scanToggle, activeBtn } = this.state;
        if (!scan) return;

        const idDigits = id.slice(-4);
        const extension = scanToggle === 'thumbnail' ? '.jpg' : `.${scanToggle}`;
        const fileName = `${activeBtn}_${idDigits}${extension}`;

        FileSaver.saveAs(scan, fileName);
    };

    rotateX = () => {
        const { rotationX } = this.state;
        this.setState({ rotationX: rotationX + (15 / 180) * Math.PI });
    };

    rotateY = () => {
        const { rotationY } = this.state;
        this.setState({ rotationY: rotationY + (15 / 180) * Math.PI });
    };

    handleScanToggle = (_, scanToggle) => {
        const { activeBtn } = this.state;
        if (!scanToggle) return;
        this.setState({ scanToggle }, () => {
            this.loadFile(activeBtn);
        });
    };

    isDisabled = (scan, positionTab) => {
        const { activeBtn } = this.state;
        const activeScan = this.getScan(activeBtn);

        let active = activeBtn;
        if (positionTab) active = positionTab;

        const getUrl = (stlUrl, plyUrl) => (scan === 'stl' ? !stlUrl : scan === 'ply' ? !plyUrl : false);

        return getUrl(activeScan?.stlUrl, activeScan?.plyUrl);
    };

    byDisplayIndex = (a, b) => {
        if (a.displayIndex < b.displayIndex) return -1;
        if (a.displayIndex > b.displayIndex) return 1;
        return 0;
    };

    render() {
        const { thumbnailUrl, scans, id: caseId } = this.props;
        const { showModal, scan, fetching, showAlert, rotationX, rotationY, scanToggle, activeBtn } = this.state;
        const { scope } = localStorage;
        const scopeArray = (scope || '').split(' ');
        const hasAccess = (rule) => scopeArray.includes(rule);
        const canDownloadFile = hasAccess('cases.scans:download');

        return (
            <div className="column-container renders-grid">
                { scans.sort(this.byDisplayIndex).map((scanElement) => {
                    const { id, label, displayLabel } = scanElement;
                    return (
                        <div className="renders-grid-box full" key={id} onClick={() => this.activateTab(label)} role="presentation">
                            <span>{displayLabel}</span>
                            <div className="renders-item">
                                <ScanThumbnail uri={scanElement.thumbnailUrl || thumbnailUrl} name={label} />
                                {
                                    scanElement.plyUrl && <i className="material-icons">search</i>
                                }
                            </div>
                        </div>
                    );
                })}
                <Modal show={showModal}>
                    <i className="material-icons" role="presentation" onClick={() => this.setState({ scan: null, showModal: false })}>close</i>
                    <div className="modal-button-group">
                        {
                            scans.map((scanElement) => <button type="button" onClick={() => this.activateTab(scanElement.label)} className={activeBtn === scanElement.label ? 'active' : ''}>{scanElement.displayLabel}</button>)
                        }
                    </div>
                    <Grid container justifyContent="center">
                        <ToggleButtonGroup
                            value={scanToggle}
                            exclusive
                            size="small"
                            onChange={this.handleScanToggle}
                            aria-label="Platform"
                        >
                            <ToggleStyled style={buttonStyle} disabled={this.isDisabled('thumbnail')} value="thumbnail">Thumbnail</ToggleStyled>
                            <ToggleStyled style={buttonStyle} disabled={this.isDisabled('ply')} value="ply">PLY</ToggleStyled>
                            <ToggleStyled style={buttonStyle} disabled={this.isDisabled('stl')} value="stl">STL</ToggleStyled>
                        </ToggleButtonGroup>
                    </Grid>

                    <div className="render-engine-wrapper">
                        {
                            fetching && <Spinner type="center" />
                        }
                        { !fetching && scan && (
                            <>
                                {
                                    scanToggle === 'stl' && (
                                        <StlViewer
                                            orbitControls
                                            url={scan}
                                            modelProps={{ rotationX, rotationY, scale: 1.2 }}
                                            style={viewerStyle}
                                        />
                                    )
                                }
                                {
                                    scanToggle === 'ply' && (
                                        <div style={viewerStyle}>
                                            <PlyViewer url={scan} />
                                        </div>
                                    )
                                }
                                {
                                    scanToggle === 'thumbnail' && (
                                        <div style={viewerStyle}>
                                            <InnerImageZoom src={scan} zoomScale={3.5} style={{ width: '100%', height: '100%' }} />
                                        </div>
                                    )
                                }
                            </>
                        )}
                    </div>
                    { scanToggle === 'stl' && (
                        <Grid container spacing={1} justifyContent="center">
                            <Grid item>
                                <Button variant="outlined" size="small" style={buttonStyle} onClick={this.rotateX}> Rotate X </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" size="small" style={buttonStyle} onClick={this.rotateY}> Rotate Y </Button>
                            </Grid>
                        </Grid>
                    ) }
                    <p className="text-center">Use your mouse buttons to pan, zoom, and rotate the image.</p>
                    <p className="text-center">Press Ctrl while moving the mouse to drag the image.</p>
                    {
                        canDownloadFile
                        && (
                            <div className="modal-footer-button-group">
                                <button type="button" className="btn btn-icon" onClick={this.handleStlDownload} disabled={fetching}>
                                    <i className="material-icons">cloud_download</i>
                                    Download
                                </button>
                            </div>
                        )
                    }
                </Modal>
                <Modal className="alert" show={showAlert}>
                    <i className="material-icons" role="presentation" onClick={() => this.setState({ showAlert: false })}>close</i>
                    <div>
                        <h3 className="text-center" style={{ paddingTop: '30px' }}>No STL File Found.</h3>
                    </div>
                </Modal>
            </div>
        );
    }
}

Renders.propTypes = {
    id: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string,
    scans: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        dentalCaseId: PropTypes.string,
        label: PropTypes.string,
    })).isRequired,
};

Renders.defaultProps = {
    thumbnailUrl: '',
};

export default Renders;
