/* eslint-disable react/jsx-indent-props */

import PropTypes from 'prop-types';
import { TableRow, TableData, TableDataWrapper, TableRowDrop } from '../..';
import UserDetail from '../UserDetail';

function UserRow({ data, onClickRow, open }) {
    return (
        <TableRow>
            <TableDataWrapper onClick={onClickRow}>
                <TableData data={data.email || ''} span="3" />
                <TableData data={data.username || ''} span="3" />
                <TableData data={data.firstName || ''} span="2" isCapitalized />
                <TableData data={data.lastName || ''} span="2" isCapitalized />
            </TableDataWrapper>
            <TableRowDrop open={open} openHeight="200px">
                <UserDetail user={data} />
            </TableRowDrop>
        </TableRow>
    );
}

UserRow.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        email: PropTypes.string,
        enabled: PropTypes.bool,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        username: PropTypes.string,
        clinicId: PropTypes.string,
        labId: PropTypes.string,
    }).isRequired,
    onClickRow: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default UserRow;
