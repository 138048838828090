import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { fetchClinics } from '../../redux/thunks';
import {
    Header,
    ClinicList,
    Search,
    Spinner,
    Pagination,
    Row,
} from '../../components';
import './styles.scss';

export default function Clinics() {
    const dispatch = useDispatch(fetchClinics);
    const navigate = useNavigate();
    const clinics = useSelector((state) => state.clinics.clinics);
    const pagination = useSelector((state) => state.clinics.pagination);
    const [fetching, setFetching] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (fetching && clinics) {
            setFetching(false);
        }
    }, [clinics]);

    useEffect(() => {
        setFetching(true);
        dispatch(fetchClinics(0, search));
    }, []);

    const onUpdateSearch = debounce((searchStr) => {
        setSearch(searchStr);
        dispatch(fetchClinics(0, searchStr));
    }, 500);
    const onAddClinic = () => {
        navigate('/create-clinic');
    };
    const onChangePage = (page) => {
        dispatch(fetchClinics(page, search));
    };
    return (
        <>
            <Header label="Clinics" />
            <main className="dashboard-container">
                <Row>
                    <div className="clinic-header">
                        <Search onChange={onUpdateSearch} label="Search clinic name" />
                        <i title="Add new clinic" role="presentation" className="material-icons fz-50 clickable" onClick={onAddClinic}>add_circle</i>
                    </div>
                </Row>
                {
                    fetching && <Spinner type="page" />
                }
                {
                    clinics && !clinics.length && !fetching
                        ? <p className="text-red text-align-center">No clinics found</p>
                        : <ClinicList clinics={clinics} />
                }
                <Pagination onChangePage={onChangePage} pagination={pagination} />
            </main>
        </>
    );
}
